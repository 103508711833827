import { RootState } from 'stores';
import { CmsCarouselBanner } from 'API/CmsApi';
import { MediaComponent, SupportedUILanguage } from './cms.interface';

export const getSupportedLanguages = ({ cms }: RootState): SupportedUILanguage[] => cms.languages;
export const getSplashScreen = ({ cms }: RootState): MediaComponent | undefined => cms.loginScreen;
export const getLogo = ({ cms }: RootState): MediaComponent | undefined => cms.logo;
export const getCustomerDisplaySplashScreen = ({ cms }: RootState): CmsCarouselBanner | undefined =>
  cms.customerDisplaySplashScreen;
export const getCustomerDisplayBasketImage = ({ cms }: RootState): CmsCarouselBanner | undefined =>
  cms.customerDisplayBasket;
export const getBestSeller = ({ cms }: RootState): MediaComponent | undefined => cms.bestSeller;
export const getCustomerDisplayHeaderIcon = ({ cms }: RootState): MediaComponent | undefined =>
  cms.customerDisplayHeaderIcon;
