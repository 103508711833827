import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';

interface OptionLabelContainerProps {
  title: string;
  option?: {
    name: string;
    price: string;
    label: string;
  } | null;
  testId: string;
}
const OptionLabelContainer = ({ title, option, testId }: OptionLabelContainerProps) => {
  const { spacing, palette, radius } = useTheme();

  const label = { color: palette.black.main };

  return (
    <Box
      sx={{
        marginTop: spacing(3),
        marginBottom: spacing(2),
        backgroundColor: palette.customGray.main,
        borderRadius: radius.sm,
        padding: `${spacing(1)} ${spacing(2)}`,
      }}
    >
      {!option && (
        <Typography variant="subtitle2" sx={label} data-testid={testId}>
          {title}
        </Typography>
      )}
      {option && (
        <>
          <Typography variant="body2" sx={label}>
            {option.label}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }} data-testid={testId}>
            <Typography variant="h6" sx={label}>
              {option.name}
            </Typography>
            <Typography variant="subtitle2" sx={label}>
              {option.price}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default OptionLabelContainer;
