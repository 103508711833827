import { Coupon } from 'typings/Coupons';
import { DineInOrder } from './dineInTypes';

export function generateFilledCouponsFromDineInOrder(
  dineInOrder: DineInOrder,
  couponsMasterData: Coupon[],
): Coupon[] {
  return dineInOrder.coupons.map((c) => {
    const masterDataCoupon = couponsMasterData.find((cmd) => cmd.couponCode === c.couponCode);
    return {
      ...c,
      description: masterDataCoupon?.description ?? '',
    };
  });
}
