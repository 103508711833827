import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { RootState } from 'stores';
import {
  PaymentMethod,
  GetPaymentMethodsRequest,
  EftPaymentTerminalStatus,
  RefundPaymentAttemptResult,
} from 'typings/Payments';
import { isValidSuccessfulAxiosResponse } from 'typings/type-guards';
import { sortBy, isEmpty } from 'lodash';

export const sendCtmpCommand = createAsyncThunk<boolean, undefined, { state: RootState }>(
  '[PAYMENTS]/sendCtmpCommand',
  async (_, { getState }) => {
    const {
      stores: { selectedStore },
      config: { payment },
    } = getState();
    const useV2Payment = payment?.v2.useInStores?.some((el) => el === selectedStore?.id);
    const response = await posApi.post(
      useV2Payment ? posApiUrls.EFT_PAYMENT_V2_CTMP : posApiUrls.EFT_PAYMENT_TERMINAL_CTMP,
    );
    if (isValidSuccessfulAxiosResponse(response)) {
      return true;
    }

    return false;
  },
);

export const getTerminalStatus = createAsyncThunk<
  EftPaymentTerminalStatus | undefined,
  undefined,
  { state: RootState }
>('[INTAKE]/getTerminalStatus', async (_, { getState }) => {
  const {
    stores: { selectedStore },
    config: { payment },
  } = getState();

  const useV2Payment = payment?.v2.useInStores?.some((el) => el === selectedStore?.id);

  const result = await posApi.get<EftPaymentTerminalStatus>(
    useV2Payment ? posApiUrls.EFT_PAYMENT_V2_TERMINAL_STATUS : posApiUrls.EFT_PAYMENT_TERMINAL_STATUS,
  );
  if (!isValidSuccessfulAxiosResponse(result) || isEmpty(result.data)) {
    throw Error('Error on getting device status');
  }

  return result.data;
});

export const sendOpenCashDrawerCommand = createAsyncThunk<boolean, undefined, { state: RootState }>(
  '[PAYMENTS]/sendOpenCashDrawerCommand',
  async (_, { getState }) => {
    const { selectedStore } = getState().stores;

    if (!selectedStore) {
      return false;
    }

    const response = await posApi.post(posApiUrls.CASH_DRAWER_OPEN);

    if (response.status === 200 || response.status === 204) {
      return true;
    }

    return false;
  },
);

export const getPaymentMethods = createAsyncThunk(
  '[PAYMENTS]/getPaymentMethods',
  async (params: GetPaymentMethodsRequest | undefined) => {
    const result = await posApi.get<PaymentMethod[]>(posApiUrls.PAYMENT_METHODS, { params });
    return sortBy(result.data, ['sortOrder', 'description']);
  },
);

export const refundPayment = createAsyncThunk<
  RefundPaymentAttemptResult,
  { orderId: string },
  { state: RootState }
>('[PAYMENTS]/refundPayment', async ({ orderId }) => {
  if (!orderId) throw new Error('Cannot refund payment without order in context');

  const response = await posApi.put<RefundPaymentAttemptResult>(posApiUrls.PAYMENT_REFUND(orderId));

  if (response.status !== 200 || isEmpty(response.data)) {
    throw Error('Payment cannot be refunded, there is an api error');
  }

  return response.data;
});
