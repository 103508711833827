import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { RootState } from 'stores';
import { mapToManyMeals } from 'stores/Intake/IntakeStoreUtils';
import { BasketDataResponse, BasketSummary, RecalculateSetRequestDto, RequestBasketItem } from 'typings/Basket';
import { BestSellerCoupon, Coupon, Meal, MealCoupon, MealSettings } from 'typings/Coupons';
import { StoreInfo } from 'typings/Store';
import { isCouponComplete } from 'utils/intake/couponsUtils';
import { generateBasketRequest } from 'utils/intake/basketUtils';

export const fetchBestSellerCoupons = createAsyncThunk<BestSellerCoupon[], StoreInfo, { state: RootState }>(
  '[COUPONS]/fetchBestSellerCoupons',
  async (selectedStore, { getState }) => {
    const { activeDeliveryType } = getState().intake;
    const { basketItems, basketCoupons } = getState().basket;
    const basketRequestPayload = {
      storeId: selectedStore.id,
      basketItems: basketItems.map((item) => {
        return {
          id: item.id,
          productSelection: {
            productId: item.itemId,
            productOptionId: item.optionId,
          },
          quantity: item.quantity,
          sliceCustomizations: item.sliceCustomizations,
        };
      }),
      totalDiscounts: [],
      pickupType: activeDeliveryType,
      deliveryCharge: null,
      selectedCoupons: basketCoupons,
    };
    const result = await posApi.put<BestSellerCoupon[]>(posApiUrls.BEST_SELLER_COUPONS_GET, basketRequestPayload);

    return result.data;
  },
);

export const fetchAllCouponsData = createAsyncThunk('[COUPONS]/fetchAllCouponsData', async () => {
  const result = await posApi.get<Coupon[]>(posApiUrls.COUPONS_GET);
  return result.data;
});

export const getCustomizationCouponMealSettings = createAsyncThunk<MealCoupon, Coupon, { state: RootState }>(
  '[COUPONS]/getCustomizationCouponMealSettings',
  async (coupon, { getState }) => {
    try {
      const result = await posApi.get<MealSettings[]>(posApiUrls.DISCOUNT_MEAL_SETTINGS(coupon.couponCode));
      const { categories, products, options } = getState().products;
      const meals = mapToManyMeals(result.data, categories, products, options);

      const mealCoupon: MealCoupon = {
        ...coupon,
        title: coupon.description,
        price: coupon.price ?? 0,
        meals,
        complete: isCouponComplete(meals),
      };
      const allMealCanBeCompleted = mealCoupon?.meals.every((x) => x.availableProducts.length > 0) === true;

      if (!allMealCanBeCompleted) throw new Error('Coupon products are not available');
      return mealCoupon;
    } catch (error) {
      console.error(`Error getting filteres for coupon code: ${coupon.couponCode}, error:`, error);
      throw error;
    }
  },
);

export const getCouponsForCurrentBasket = createAsyncThunk<Coupon[], void, { state: RootState }>(
  '[COUPONS]/getCouponsForCurrentBasket',
  async (_, { getState }) => {
    const { basket, intake, customer } = getState();
    const { basketItems, basketCoupons, manualDeliveryCharge, autoAddedItems } = basket;
    const { activeDeliveryType, isEatIn, selectedOrderCustomer } = intake;
    const { customerCreditToUse } = customer;

    const basketRequest = generateBasketRequest({
      requestedBasket: [...basketItems],
      coupons: basketCoupons,
      activeDeliveryType,
      manualDeliveryCharge,
      autoAddedItems,
      isEatIn,
      customerCreditToUse,
    });
    const result = await posApi.put<BasketDataResponse>(`${posApiUrls.BASKET_RECALCULATE}`, basketRequest, {
      params: { getCoupons: true },
    });
    return result.data.availableCoupons ?? [];
  },
);

export const getRecalculatedSet = createAsyncThunk<
  MealCoupon | undefined,
  { couponCode: string; meals: Meal[] },
  { state: RootState }
>('[BASKET]/replaceInBasket', async (coupon, { getState }) => {
  const { activeDeliveryType } = getState().intake;
  const { couponToCustomize } = getState().coupons;

  if (!couponToCustomize) return undefined;

  const request = {
    items: coupon.meals.map(
      (meal, index) =>
        ({
          id: index,
          productSelection: {
            productId: meal.selectedProduct?.baseProduct.id,
            productOptionId: meal.selectedProduct?.originalOptionId,
          },
          quantity: meal.selectedProduct?.quantity,
          sliceCustomizations: meal.selectedProduct?.sliceCustomizations,
        } as RequestBasketItem),
    ),
    couponCode: coupon.couponCode,
    pickupType: activeDeliveryType,
  } as RecalculateSetRequestDto;

  const result = await posApi.put<BasketSummary>(posApiUrls.SET_RECALCULATE, request);
  return { ...couponToCustomize, ...coupon, price: result.data.total };
});
