import { createAsyncThunk } from '@reduxjs/toolkit';
import { IntakeStatuses, PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { RootState } from 'stores';
import { setAlert } from 'stores/Alerts/alerts.slice';
import { restartBasketState } from 'stores/Basket/basket.slice';
import { handleScannedProductBarcode } from 'stores/Basket/basket.thunk-actions';
import { fetchCustomerDisplay, fetchPosImages } from 'stores/Cms/cms.slice';
import { resetLoggingCorrelationId } from 'stores/Config';
import { fetchBestSellerCoupons } from 'stores/Coupons/coupons.thunk-actions';
import { getCustomerSearchResults, restartCustomerState } from 'stores/Customer';
import { restartDineInState } from 'stores/DineIn/dineIn.slice';
import {
  changeDeliveryType,
  clearPhoneNumberSearchQuery,
  restartIntakeState,
  setCustomerSearchQuery,
  setIntakeCustomer,
} from 'stores/Intake';
import { setCustomizeProduct } from 'stores/Products/products.slice';
import {
  fetchAllProductGroups,
  fetchAllProductOptions,
  fetchAllProductSubtypes,
  fetchBestSellerProducts,
  fetchCategoriesWithProducts,
} from 'stores/Products/products.thunk-actions';
import { fetchAllToppings, fetchToppingCategories } from 'stores/Toppings/toppings.thunk-actions';
import { StoreInfo } from 'typings/Store';
import { checkIfEANCode, checkIfLoyaltyCode } from 'utils/applicationContainer/keyScannerUtils';
import { getAllRewards } from './Loyalty/loyalty.thunk-actions';

export const handleScanEvent = createAsyncThunk<void, string, { state: RootState }>(
  '[COMBINED]/handleScanEvent',
  async (scannedValue, { getState, dispatch }) => {
    const {
      intake: { intakeStatus },
      products: { productToCustomize },
      orderPayment: { showOrderPayment, payments },
      payments: { settlePayment },
    } = getState();
    if (intakeStatus === IntakeStatuses.preFinalize) return;
    if (
      (showOrderPayment === true && !(payments.find((x) => x.isActive)?.paymentStep === 'provideQrCode')) ||
      !!settlePayment
    )
      return;
    if (productToCustomize) return;

    const sanitizedValue = scannedValue.replace('ArrowDown', '');

    if (checkIfEANCode(sanitizedValue)) {
      dispatch(handleScannedProductBarcode(sanitizedValue));
      return;
    }

    if (checkIfLoyaltyCode(sanitizedValue)) {
      dispatch(setCustomerSearchQuery(sanitizedValue));
      dispatch(
        getCustomerSearchResults({
          customerSearchQuery: sanitizedValue,
          currentPageIndex: 0,
          loadAdditional: true,
        }),
      );
      return;
    }

    dispatch(setAlert({ header: 'Scanned value error', message: `Code:${sanitizedValue} is unknown` }));
  },
);

export const clearOrderEdition = createAsyncThunk<void, void, { state: RootState }>(
  '[COMBINED]/clearOrderEdition',
  async (_, { getState, dispatch }) => {
    const { intake } = getState();
    if (!intake.editMode) return;
    dispatch(restartOrder());
  },
);
export const restartOrder = createAsyncThunk<void, void, { state: RootState }>(
  '[COMBINED]/restartOrder',
  async (_, { dispatch }) => {
    dispatch(restartBasketState());
    dispatch(restartIntakeState());
    dispatch(changeDeliveryType());
    dispatch(restartDineInState());
    dispatch(restartCustomerState());
    dispatch(setCustomizeProduct());
    dispatch(resetLoggingCorrelationId());
    dispatch(clearPhoneNumberSearchQuery());
  },
);

export const removeCustomerFromOrder = createAsyncThunk<void, void, { state: RootState }>(
  '[COMBINED]/restartOrder',
  async (_, { getState, dispatch }) => {
    dispatch(setIntakeCustomer());
    dispatch(restartCustomerState());

    const { intake, stores, config } = getState();

    if (config.featureFlags.OfflineModule_ShowRewardsOnPos) {
      dispatch(
        getAllRewards({
          customerId: 0,
          pickupType: intake.activeDeliveryType,
          storeId: stores.selectedStore?.id ?? 0,
        }),
      );
    }
  },
);

export const fetchStaticContents = createAsyncThunk<void>(
  '[COMBINED]/fetchStaticContents',
  async (_, { dispatch }) => {
    dispatch(fetchPosImages());
    dispatch(fetchCustomerDisplay());
  },
);

export const fetchAllProductData = createAsyncThunk<void, StoreInfo, { state: RootState }>(
  '[COMBINED]/fetchAllProductData',
  async (selectedStore, { getState, dispatch }) => {
    dispatch(fetchCategoriesWithProducts()).then(() => {
      dispatch(fetchAllProductGroups());
      dispatch(fetchAllProductOptions());
      dispatch(fetchAllProductSubtypes());
      dispatch(fetchAllToppings());
      dispatch(fetchToppingCategories());
      dispatch(fetchBestSellerProducts());
      dispatch(fetchBestSellerCoupons(selectedStore));

      const { intake, config } = getState();

      if (config.featureFlags.OfflineModule_ShowRewardsOnPos) {
        dispatch(
          getAllRewards({
            pickupType: intake.activeDeliveryType,
            storeId: selectedStore.id,
            customerId: 0,
          }),
        );
      }
    });
  },
);
