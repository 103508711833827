import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ spacing, radius, sizing, palette }) => {
  return {
    container: {
      borderRadius: radius.sm,
      padding: spacing(2),
      width: sizing.alertWidth,
      border: `1px solid ${palette.grey[900]}`,
      backgroundColor: palette.grey[900],
      color: palette.white,
    },
    containerSuccess: {
      borderColor: palette.green[500],
      backgroundColor: palette.green[100],
      color: palette.black.main,
      '& $dismissButton': {
        color: palette.green[800],
      },
    },
    containerInfo: {
      borderColor: palette.blue[500],
      backgroundColor: palette.blue[50],
      color: palette.black.main,
      '& $dismissButton': {
        color: palette.blue[600],
      },
    },
    containerWarning: {
      borderColor: palette.yellow[600],
      backgroundColor: palette.yellow[100],
      color: palette.black.main,
      '& $dismissButton': {
        color: palette.yellow[800],
      },
    },
    containerError: {
      borderColor: palette.red[500],
      backgroundColor: palette.red[100],
      color: palette.black.main,
      '& $dismissButton': {
        color: palette.red[900],
      },
    },
    messageContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: spacing(1),
    },
    dismissButton: {
      color: palette.green[600],
      float: 'right',
    },
  };
});
export default buildClasses;
