import { AddressBookEntry, ProfileAddressDto } from 'stores/Intake/intakeTypes';

export function removeAddressesDuplicates(
  privateAddressesCollection: ProfileAddressDto[],
  companyAddressesCollection: ProfileAddressDto[],
): ProfileAddressDto[] {
  const companyFullAddresses = companyAddressesCollection
    .filter((companyAddr) => companyAddr.fullAddress)
    .map((companyAddr) => companyAddr.fullAddress);
  const resultPrivateAddresses = privateAddressesCollection.filter(
    (privateAddr) => privateAddr.fullAddress && !companyFullAddresses.includes(privateAddr.fullAddress),
  );

  return [...resultPrivateAddresses, ...companyAddressesCollection];
}
