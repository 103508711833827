export function saveLocalStorageItem<T>(key: string, value: T): void {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorageItem<T>(key: string): T | undefined {
  const item = window.localStorage.getItem(key);
  if (!item) return undefined;
  const typedItem = JSON.parse(item) as unknown;
  return typedItem as T;
}

export enum LocalStorageItems {
  useCustomerDisplay = 'pos.instance.useCustomerDisplay',
  idleTimeSpan = 'pos.instance.idleTimeoutTimeSpan',
  useScanner = 'pos.instance.useScanner',
  skipFinalizePaymentStep = 'pos.instance.skipFinalizePaymentStep',
  finalizePaymentTime = 'pos.instance.finalizePaymentTime',
  zoomLevel = 'pos.instance.zomLevel',
}
