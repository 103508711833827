import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cancelLocalOrder } from 'stores/LocalOrders';
import { DineInOrderStatus } from 'containers/Intake/IntakeConsts';
import { DineInReceiptDiscount, DineInReceiptProduct } from 'typings/Basket';
import { initOrderEdition } from 'stores/Intake';
import {
  fetchAllDineInOpenOrders,
  postNewTab,
  getDineInOrder,
  updateDineInOrder,
  fetchFilteredDineInOrders,
  swapDineInTables,
  postFinalizeDineInOrder,
} from './dineIn-thunk.actions';
import { DineInOrder, DineInRefreshConfig, DineInState } from './dineInTypes';

export const initialState: DineInState = {
  isTableChangeInProgress: false,
  isIterationPending: false,
  dineInFilters: {
    orderStatus: DineInOrderStatus.Opened,
    orderBy: 'TableIdentifier',
    sortingOrder: 'asc',
    rowIndex: 0,
    rowsPerPage: 10,
  },
};

const dineInState = createSlice({
  name: '[DINEIN]',
  initialState,
  reducers: {
    restartDineInState: () => initialState,
    selectDineInOrder: (state, action: PayloadAction<DineInOrder | undefined>) => ({
      ...state,
      selectedDineInOrder: action.payload,
    }),
    setCreateDineInOrderStatus(state, action: PayloadAction<DineInState['createDineInOrderDetails']>) {
      // const newManualPriceOverride = action.payload?.status === 'SUCCESS' ? undefined : state.manualPriceOverride;
      return {
        ...state,
        createDineInOrderDetails: action.payload,
        // manualPriceOverride: newManualPriceOverride,
      };
    },
    setPendingIteration: (state) => ({
      ...state,
      isIterationPending: true,
    }),
    clearSelectedDineInOrder: (state) => ({
      ...state,
      selectedDineInOrder: undefined,
    }),
    restartDineInOrders: (state) => ({
      ...state,
      dineInOrders: undefined,
    }),
    setIsTableChangeInProgress: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isTableChangeInProgress: action.payload,
    }),
    resetDineInFilters: (state) => ({
      ...state,
      dineInFilters: initialState.dineInFilters,
    }),
    setDineInFilters: (state, action: PayloadAction<DineInRefreshConfig>) => ({
      ...state,
      dineInFilters: action.payload,
    }),
    updatePendingItems: (state, action: PayloadAction<(DineInReceiptProduct | DineInReceiptDiscount)[]>) => ({
      ...state,
      pendingItems: action.payload,
    }),
    clearPendingItems(state) {
      return { ...state, pendingItems: undefined };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllDineInOpenOrders.fulfilled, (state, action) => ({
      ...state,
      dineInOrders: action.payload,
    }));
    builder.addCase(fetchFilteredDineInOrders.fulfilled, (state, action) => ({
      ...state,
      dineInOrders: action.payload,
    }));
    builder.addCase(postNewTab.rejected, (state) => ({
      ...state,
      createDineInOrderDetails: undefined,
    }));
    builder.addCase(postFinalizeDineInOrder.fulfilled, (state, action) => {
      if (action.payload.shouldClear) {
        return {
          ...state,
          selectedDineInOrder: undefined,
        };
      }
      return state;
    });
    builder.addCase(getDineInOrder.fulfilled, (state, action) => ({
      ...state,
      selectedDineInOrder: action.payload,
    }));
    builder.addCase(updateDineInOrder.pending, (state) => ({
      ...state,
      isIterationPending: true,
    }));
    builder.addCase(updateDineInOrder.fulfilled, (state) => ({
      ...state,
      selectedDineInOrder: undefined,
      isIterationPending: false,
    }));
    builder.addCase(updateDineInOrder.rejected, (state) => ({
      ...state,
      isIterationPending: true,
    }));
    builder.addCase(cancelLocalOrder.fulfilled, (state) => ({
      ...state,
      selectedDineInOrder: undefined,
    }));
    builder.addCase(swapDineInTables.fulfilled, (state) => ({
      ...state,
      isTableChangeInProgress: false,
    }));
    builder.addCase(swapDineInTables.rejected, (state) => ({
      ...state,
      isTableChangeInProgress: false,
    }));
    builder.addCase(initOrderEdition.fulfilled, (state) => ({
      ...initialState,
      dineInFilters: state.dineInFilters,
      dineInOrders: state.dineInOrders,
    }));
  },
});

const { reducer } = dineInState;
export const {
  selectDineInOrder,
  setPendingIteration,
  setCreateDineInOrderStatus,
  setIsTableChangeInProgress,
  clearSelectedDineInOrder,
  restartDineInState,
  resetDineInFilters,
  setDineInFilters,
  restartDineInOrders,
  updatePendingItems,
  clearPendingItems,
} = dineInState.actions;

export default reducer;
