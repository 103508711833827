import React, { useEffect } from 'react';
import i18next from 'i18next';
import { fetchTranslations, initBaseUrl } from 'API/CmsApi';
import { fetchLanguages } from 'stores/Cms/cms.slice';
import { useAppDispatch } from 'hooks/useRedux';
import { fetchStaticContents } from 'stores/combined.actions';

const CmsHandler: React.FC = () => {
  const dispatch = useAppDispatch();
  initBaseUrl();

  const initCmsData = async () => {
    dispatch(fetchLanguages());
    dispatch(fetchStaticContents());
    const allLabels = await fetchTranslations();
    allLabels.data.forEach((labels) => {
      i18next.addResourceBundle(labels.locale, labels.namespace, labels.resource);
    });
  };

  useEffect(() => {
    initCmsData();
  }, []);

  return <></>;
};

export default CmsHandler;
