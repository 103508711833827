import React from 'react';
import { Typography } from '@mui/material';
import { HybridModeMessages } from 'typings/HybridMode';
import { getHybridModeCommunicator } from 'utils/hybridMode/hybridModeCommunicationUtils';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useRedux';
import { getIntakeStateToTransfer } from 'stores/HybridMode/hybridMode.selector';
import { useTheme } from '@mui/system';

interface ConnectionLostDialogProps {
  open: boolean;
  offlineModeEnabled: boolean;
}

const ConnectionLostDialog: React.FC<ConnectionLostDialogProps> = ({ open, offlineModeEnabled }) => {
  const { palette } = useTheme();
  const [t] = useTranslation('common');

  const intakeStateToTransfer = useAppSelector(getIntakeStateToTransfer());

  function switchToOfflineMode() {
    const hybridModeCommunicator = getHybridModeCommunicator();

    hybridModeCommunicator.send(HybridModeMessages.Hybrid.Commands.SwitchToOfflinePos, intakeStateToTransfer);
  }

  const message = offlineModeEnabled ? t('Swapping to Offline mode') : t('Cloud not available');

  return (
    <ModalContainer
      onClose={switchToOfflineMode}
      open={open}
      title={t('Connection lost')}
      primaryButton={{
        form: 'conectionLostForm',
        testId: 'switch-to-offline-confirm',
        label: t('Ok, got it'),
        action: switchToOfflineMode,
      }}
      testId="switch-to-offline-modal"
      allowCloseOnlyByButton
      hideCloseIcon
    >
      <Typography
        variant="body1"
        textAlign="center"
        sx={{ color: palette.black.main, margin: 2, textAlign: 'left' }}
      >
        {message}
      </Typography>
      {intakeStateToTransfer && offlineModeEnabled && (
        <Typography
          variant="body1"
          textAlign="center"
          sx={{ color: palette.black.main, margin: 2, textAlign: 'left' }}
        >
          {t('Basket will be transferred')}
        </Typography>
      )}
    </ModalContainer>
  );
};
export default ConnectionLostDialog;
