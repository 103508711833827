import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { ConnectivityStatusEnum } from 'services/ConnectivityStatusService';
import { MachineType } from 'typings/Auth';
import { PaymentMethodCode } from 'typings/Payments';

export interface Dictionary<T> {
  [key: string]: T;
}

export type FeatureFlags = Dictionary<boolean>;

export type EditMode =
  | {
      mode: 'full' | 'paid';
      orderId: string;
      canEditBasket: boolean;
      canChangePayment: boolean;
      basketEdited: boolean;
    }
  | undefined;

export type InstanceType = 'InStore' | 'Central';

export enum CurrencySymbolPosition {
  before,
  after,
  beforeSpace,
  afterSpace,
}

export interface PaymentConfiguration {
  minimumChangeAmount: number;
  currencySymbolPosition: CurrencySymbolPosition;
  decimalPlacesForEdition: number;
  decimalPlacesForDisplay: number;
  currencySymbol: string;
  currencyISO: string;
  decimalSeparator: string;
  thousandsSeparator: string;
  culture: string;
  v2: PaymentConfigurationV2;
}

export interface PaymentMethodQuickValuesConfiguration {
  paymentMethodQuickValues: PaymentMethodQuickValues[];
}

export interface PaymentMethodQuickValues {
  code: string;
  values: number[];
}

export interface PaymentConfigurationV2 {
  enableTipping: boolean;
  useInStores: number[];
  splitPaymentConfiguration: SplitPaymentConfiguration;
  automaticFinalizationDefaultTime: number;
  finalizeAutomaticallyForPaymentMethods: number[];
  skipFinalizePaymentStep: boolean;
}

export interface SplitPaymentConfiguration {
  enableSplitPayment: boolean;
  excludedPickupTypes: PickUpTypesValues[];
}

export interface MachineActivityConfig {
  enabled: boolean;
  updateIntervalInSecods: number;
}

export enum AddressControlType {
  Label = 'Label',
  TextBox = 'TextBox',
  ComboBox = 'ComboBox',
}

export enum AddressSectionType {
  Street = 'street',
  StreetNumber = 'streetNumber',
  StreetNumberAddition = 'streetNumberAddition',
  BuildingName = 'buildingName',
  EntranceNumber = 'entranceNumber',
  ApartmentNumber = 'apartmentNumber',
  StreetType = 'streetType',
  Zipcode = 'zipcode',
  City = 'city',
  District = 'district',
  AddressType = 'addressType',
  AddressRemark = 'addressRemark',
  SuggestionBox = 'suggestionBox',
  Region = 'region',
  GuiLabel = 'guiLabel',
}

export interface AddressInputConfigurationElement {
  divCssClass: string;
  format?: string;
  controlType: AddressControlType;
  controlValues?: Dictionary<string>;
  defaultText: string;
  cmsKey: string;
  isOptional: boolean;
  requiredErrorMessage?: string;
  requiredErrorMessageCmsKey?: string;
  formatErrorMessage?: string;
  formatErrorMessageCmsKey?: string;
  sectionType: AddressSectionType;
  line: number;
  pos: number;
}
export interface AddressInputConfiguration {
  configurationElements: AddressInputConfigurationElement[];
}

export interface IdentityServerSettingsDto {
  url: string;
  grantType: string;
  clientId: string;
  codeChallengeMethod: string;
  responseType: string;
  scope: string[];
}

export interface ToppingsCustomizationConfiguration {
  maximumExtraToppings: number;
  maximumTotalToppingsForCyo: number;
  maximumNumberOfDuplicatesToppings: number;
}

export interface EftPaymentSettings {
  statusPollingIntervalInSeconds: number;
  manualFinishEnabledDelayInSeconds: number;
  additionalMethodsWhenEftIsForced: string[];
  hideManualFinishButton: boolean;
}

export interface IntakeSettings {
  hideCustomerSearch: boolean;
  pizzaCategoryCode: string;
  otherGroupCode: string;
  sortProductsByName: boolean;
}

export interface ConfigResponse {
  themeKey: string;
  instrumentationKey: string;
  instanceType: InstanceType;
  useNotifications: boolean;
  eftPaymentSettings: EftPaymentSettings;
  maxUserIdleTime: string;
  payment: PaymentConfiguration;
  paymentMethodsConfiguration: PaymentMethodConfiguration;
  addressInput: AddressInputConfiguration;
  identityServer: IdentityServerSettingsDto;
  toppingsCustomizationConfiguration: ToppingsCustomizationConfiguration;
  usePagination: {
    productsPage: boolean;
    couponsPage: boolean;
  };
  cmsUiSettings: {
    url: string;
    useCms: boolean;
  };
  hiddenRoutes: string[];
  useZipCodeAddressSuggestions: boolean;
  useDeliveryAddressCheck: boolean;
  machineActivityUpdate?: MachineActivityConfig;
  osmKebabMenuVisibilitySettings: {
    printReceipt: boolean;
    emailInvoice: boolean;
    vatReturnInvoice: boolean;
    downloadOrder: boolean;
    repeatOrder: boolean;
    addCustomerToBlackList: boolean;
    addCustomerCredit: boolean;
    addComment: boolean;
    faxOrder: boolean;
  };
  defaultIanaTimeZone: string;
  intakeSettings: IntakeSettings;
  defaultStoreOnSiteMachineType: MachineType;
  maxPriceOverride: number;
  maxCustomerCreditAssignment: number;
  maxDeliveryChargeOverride: number;
  quickPaymentValues: PaymentMethodQuickValuesConfiguration;
  etaConfirmationNotifierEnabled: boolean;
  fiscalizationConfiguration: FiscalizationConfiguration;
  etaAdjustmentConfiguration: EtaAdjustmentConfiguration;
}

export enum UpgradeStatus {
  required = 'Required',
  upToDate = 'UpToDate',
  failure = 'Failure',
}

export interface PaymentMethodConfiguration {
  deliveryPaymentPlatformId?: string;
  payOnAccountMethodCode?: PaymentMethodCode;
  pickupPaymentPlatformId?: string;
  takeAwayPaymentPlatformId?: string;
}
export interface ConfigState extends Partial<ConfigResponse> {
  configLoaded: boolean;
  featureFlags: FeatureFlags;
  themeKey: string;
  connectivityStatus: ConnectivityStatusEnum;
  loggingCorrelationId?: string;
  showFirstTimeLaunch: boolean;
  appVersion: AppVersionInformation;
  appConfigStatus?: UpgradeStatus;
  syncStatus?: SyncStatus;
  syncHistory: SyncHistoryRecord[];
  zoomSettings?: ZoomSettings;
}

export interface ZoomSettings {
  levels: number[];
}
export interface AppVersionInformation {
  baseVersion: string;
  gitSha?: string;
}

export enum DataSyncStatus {
  dataNotDownloaded = 'DataNotDownloaded',
  inProgress = 'InProgress',
  upToDate = 'UpToDate',
  updateRequired = 'UpdateRequired',
}

export enum DataSyncHistoryStatus {
  inProgress = 'InProgress',
  succeeded = 'Succeeded',
  aborted = 'Aborted',
  failed = 'Failed',
}

export interface SyncStatus {
  status: DataSyncStatus;
  lastSuccessfulSyncTimestamp: string;
}

export interface SyncHistoryRecord {
  id: number;
  startTime: string;
  endTime?: string;
  status: DataSyncHistoryStatus;
  errorMessage?: string;
  errorDetails?: string;
}

export interface FiscalizationConfiguration {
  awaitingFiscalizationNotificationIntervalInSeconds: number;
  isActive: boolean;
  fiscalProvider: string;
}

export interface EtaAdjustmentConfiguration {
  showEta: boolean;
  deliveryEtaAdjustments: number[];
  pickupEtaAdjustments: number[];
}
