import { CSSObject } from 'tss-react';

export function Pulse(scaleFactor = 1.5): CSSObject {
  const animation = {
    '0%': {
      transform: 'scale(1) ',
    },
    '50%': {
      transform: `scale(${scaleFactor})`,
    },
    '100%': {
      transform: 'scale(1)',
    },
  };
  return animation;
}
export function Rotate(from = 0, to = 90): CSSObject {
  const animation = {
    '0%': {
      transform: `rotation(${from}deg) `,
    },
    '100%': {
      transform: `rotate(${to}deg)`,
    },
  };
  return animation;
}
export function Jump(jumpPx = '15px', color = 'black'): CSSObject {
  const animation = {
    '0%': {
      transform: 'translate(0,0)',
    },
    '16%': {
      transform: `translate(0,-${jumpPx})`,
      background: color,
    },
    '33%': {
      transform: 'translate(0,0)',
    },
    '100%': {
      transform: 'translate(0,0)',
    },
  };
  return animation;
}
