import { CSSObject } from '@mui/material/styles';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { BASE_THEME, genericS4DThemeColors } from './base.theme.css';

const DEMO_COLORS = {
  ...BASE_THEME.palette,
  primary: {
    main: '#4a7d78',
    light: '#80a4a1',
    dark: '#416d69',
    contrastText: '#fff',
  },
  secondary: {
    ...BASE_THEME.palette.secondary,
    main: '#ededed',
  },
  error: {
    ...BASE_THEME.palette.error,
    main: '#D9534F',
  },
  success: {
    light: '#81c784',
    main: '#4caf50',
    dark: '#388e3c',
    contrastText: '#fff',
  },
};

export const DEMO = {
  ...BASE_THEME,
  palette: {
    ...DEMO_COLORS,
  },
  components: {
    ...BASE_THEME.components,
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: `${genericS4DThemeColors.primaryDark}`,
        },
        arrow: {
          color: `${genericS4DThemeColors.primaryDark}`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        colorSecondary: {
          color: 'white',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: genericS4DThemeColors.tertiaryFont,
        },
        secondary: {
          color: genericS4DThemeColors.secondaryDark,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ...(BASE_THEME.components?.MuiCssBaseline?.styleOverrides as CSSObject),
        '::-webkit-scrollbar-thumb': {
          background: genericS4DThemeColors.primaryMain,
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: genericS4DThemeColors.primaryMain,
        },
      },
    },
  },
};
