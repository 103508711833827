import { jwtDecode } from 'jwt-decode';
import { DecodedToken } from 'typings/Auth/index';

const verifyUser = (): boolean => {
  const token = localStorage.getItem('user_token');
  if (token !== null) {
    const decodedToken = jwtDecode(token) as DecodedToken;
    if (decodedToken && Date.now() >= Number(decodedToken.exp) * 1000) {
      // check expiration date
      return false;
    }
    return true;
  }
  return false;
};

export default verifyUser;
