import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Jump } from 'themes/animations.css';
import { keyframes } from '@mui/system';

const buildClasses = makeStyles()(({ palette, spacing }) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    dot: {
      background: palette.secondary.main,
      height: spacing(1),
      width: spacing(1),
      borderRadius: spacing(0.5),
      marginRight: spacing(0.75),
      '&:nth-last-of-type(1)': {
        animation: `${keyframes`${Jump(spacing(0.75), palette.black.main)}`} 1.2s .6s ease-in infinite`,
      },
      '&:nth-last-of-type(2)': {
        animation: `${keyframes`${Jump(spacing(0.75), palette.black.main)}`} 1.2s .3s ease-in infinite`,
      },
      '&:nth-last-of-type(3)': {
        animation: `${keyframes`${Jump(spacing(0.75), palette.black.main)}`} 1.2s 0s ease-in infinite`,
      },
    },
  };
});

const JumpingDotsLoader: React.FC = () => {
  const { classes } = buildClasses();
  return (
    <div className={classes.root}>
      <span className={classes.dot} />
      <span className={classes.dot} />
      <span className={classes.dot} />
    </div>
  );
};

export default JumpingDotsLoader;
