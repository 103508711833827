import { makeStyles } from 'tss-react/mui';
import { keyframes } from '@mui/system';

const buildClasses = makeStyles()(({ palette, sizing, radius }) => {
  return {
    itemQuantity: {
      animationName: keyframes`'0%': {
        transform: 'scale(1)',
      },
      '50%': {
        transform: 'scale(1.5)',
      },
      '100%': {
        transform: 'scale(1)',
      }`,
      animationDuration: '0.25s',
      animationTimingFunction: 'ease-in-out',
      backgroundColor: palette.customGray.light,
      fontWeight: 'bold',
      color: palette.black.main,
      textAlign: 'center',
      justifySelf: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: '25px',
    },
    roundContainer: {
      minWidth: sizing.buttonRound.md,
      minHeight: sizing.buttonRound.md,
      borderRadius: radius.round,
    },
    squareContainer: {
      width: sizing.buttonBox.sm,
      height: sizing.buttonBox.sm,
      borderRadius: radius.sm,
    },
    black: {
      backgroundColor: palette.black.main,
      color: palette.white,
    },
    transparent: {
      backgroundColor: 'transparent',
    },
    roundSm: {
      minWidth: sizing.buttonRound.sm,
      minHeight: sizing.buttonRound.sm,
      width: sizing.buttonRound.sm,
      height: sizing.buttonRound.sm,
    },
  };
});

export default buildClasses;
