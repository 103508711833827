import { CSSObject, Theme } from '@mui/material/styles';
import brandLogo from 'assets/logos/SRE/logo.svg';

import { BASE_THEME } from './base.theme.css';

const SRE_COLORS = {
  primary: {
    main: '#E9B530',
    light: '#E9B530',
    dark: '#a37f22',
    contrastText: '#fff',
  },
  error: {
    ...BASE_THEME.palette.error,
    main: '#C8102E',
    dark: '#6f0094',
    light: '#df80ff',
  },
  success: {
    light: '#81c784',
    main: '#0A8020',
    dark: '#4C6F01',
    contrastText: '#fff',
  },
};

export const SPARE_RIB_EXPRESS = {
  ...BASE_THEME,
  brandLogo,
  loginImage: {
    url: brandLogo,
    backgroundSize: 'contain',
  },
  palette: {
    ...BASE_THEME.palette,
    ...SRE_COLORS,
    appBar: {
      main: '#1E1E1C',
      subMain: '#DBE1E3',
      subMainText: 'black',
    },
    virtualReceipt: {
      items: {
        discount: {
          ...BASE_THEME.palette.virtualReceipt.items.discount,
          error: {
            icon: 'rgb(255,41,41)',
            background: SRE_COLORS.error.light,
          },
        },
      },
    },
  },
  components: {
    ...BASE_THEME.components,
    MuiCssBaseline: {
      styleOverrides: {
        ...(BASE_THEME.components?.MuiCssBaseline?.styleOverrides as CSSObject),
        '::-webkit-scrollbar-thumb': {
          background: '#6B6E79',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: '#6B6E79',
        },
      },
    },
  },
} as Theme;
