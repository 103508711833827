/* eslint-disable no-console */
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import {
  AutoAddedItem,
  BasketData,
  BasketItem,
  BasketRequestParams,
  BoundedBasketItem,
  DineInItem,
  ItemPriceDetails,
} from 'typings/Basket';
import { Coupon, MealCoupon } from 'typings/Coupons';
import { ProfileAddress, OrderDeliveryAddress } from 'stores/Intake';
import { Product, Topping, SliceCustomization, ToppingDisplayModel } from 'typings/Products';
import { DineInOrder } from 'stores/DineIn/dineInTypes';
import uuidGenerator from 'utils/GuidGenerator';
import canAddDeliveryCharge, { getDeliveryAddress } from './IntakeUtils';

interface GenerateBasketRequestProps {
  requestedBasket: BasketItem[];
  coupons: Coupon[] | MealCoupon[];
  activeDeliveryType: PickUpTypesValues;
  manualDeliveryCharge: number | null;
  manualPriceOverride?: number;
  selectedDeliveryAddress?: ProfileAddress;
  manuallyFilledAddress?: OrderDeliveryAddress;
  autoAddedItems?: AutoAddedItem[];
  isEatIn: boolean;
  customerCreditToUse?: number;
}
export function generateBasketRequest({
  requestedBasket,
  coupons,
  activeDeliveryType,
  manualDeliveryCharge,
  manualPriceOverride,
  selectedDeliveryAddress,
  manuallyFilledAddress,
  autoAddedItems,
  isEatIn,
  customerCreditToUse,
}: GenerateBasketRequestProps): BasketRequestParams {
  return {
    basketItems: requestedBasket
      .filter((itm) => !itm.ignoreInPriceCalculation)
      .map((item) => {
        return {
          id: item.id,
          productSelection: {
            productId: item.itemId,
            productOptionId: item.optionId,
          },
          quantity: item.quantity,
          sliceCustomizations: item.sliceCustomizations?.map((sc) => {
            return {
              sliceProductId: sc.sliceProductId,
              sliceProductName: sc.sliceProductName,
              toppingsConfiguration: sc.toppingsConfiguration,
            } as SliceCustomization;
          }),
          doNotGroup: item.doNotGroup ?? false,
          remark: item.remark,
        };
      }),
    totalDiscounts: [
      {
        discountValue: 0,
      },
    ],
    pickupType: activeDeliveryType,
    deliveryCharge: canAddDeliveryCharge(manualDeliveryCharge, activeDeliveryType),
    selectedCoupons: coupons?.map((cp) => {
      return {
        couponCode: cp.couponCode,
        couponId: cp.couponId,
        linkedBaskedItemIds: cp.linkedBaskedItemIds,
        singleUseCouponCode: cp.singleUseCouponCode,
      };
    }),
    manualPriceOverride,
    deliveryAddressInput: getDeliveryAddress(selectedDeliveryAddress, manuallyFilledAddress),
    autoAddedItems,
    isEatIn: activeDeliveryType === PickUpTypesValues.takeAway && isEatIn,
    customerCreditToUse,
  };
}

export function getNextBasketItemId(currentBasketItems: BasketItem[]): number {
  if (currentBasketItems.length === 0) {
    return 0;
  }
  const nextBasketItemId = Math.max(...currentBasketItems.map((basketItem) => basketItem.id)) + 1;
  return nextBasketItemId;
}

// TODO: Move to using guid as basket items identifier
export function getNextItemIdForCurrentIntake(
  currentBasket: BasketItem[],
  selectedDineInOrder?: DineInOrder,
): number {
  const selectedDineIOrderItems = selectedDineInOrder?.items ?? [];
  const nextIdFromDineIn = getNextBasketItemId(selectedDineIOrderItems);
  const nextIdFromBasket = getNextBasketItemId(currentBasket);

  return nextIdFromBasket > nextIdFromDineIn ? nextIdFromBasket : nextIdFromDineIn;
}

export const addBasketCouponHelper = (requestedCoupon: Coupon, appliedCoupons: Coupon[]): Coupon[] => {
  const newCouponList = [...appliedCoupons];
  const existingCoupon = newCouponList.find((el) => el === requestedCoupon);
  if (existingCoupon === undefined) {
    newCouponList.push(requestedCoupon);
  }

  return newCouponList;
};

export function reviewBasketItems(
  requestedBasket: BasketItem[],
  recalculatedItemDetails: ItemPriceDetails[],
  dineInOrder?: DineInOrder,
): BasketItem[] {
  const newBasket: BasketItem[] = [];

  const generatedBasketIds: number[] = [];

  recalculatedItemDetails.forEach((ipd) => {
    const basketItemMatchingIpd = requestedBasket.find((el) => el.id === ipd.basketItemId);

    if (basketItemMatchingIpd !== undefined) {
      const itemAlreadyInNewBasket = newBasket.find((el) => el.id === ipd.basketItemId);

      if (itemAlreadyInNewBasket) {
        const nextId =
          generatedBasketIds.length > 0
            ? Math.max(...generatedBasketIds) + 1
            : Math.max(...requestedBasket.map((item) => item.id)) + 1;

        if (dineInOrder) {
          updateDineInOrderWithNewItemId(dineInOrder, nextId, ipd.quantity, ipd);
        }

        generatedBasketIds.push(nextId);
        newBasket.push({
          ...basketItemMatchingIpd,
          id: nextId,
          receiptId: ipd.receiptItemId,
          quantity: ipd.quantity,
          splittedFromId: basketItemMatchingIpd.id,
        });
      } else {
        newBasket.push({
          ...basketItemMatchingIpd,
          id: ipd.basketItemId,
          receiptId: ipd.receiptItemId,
          quantity: ipd.quantity,
        });
      }
    }
  });

  return newBasket;
}

export function createBoundedProducts(
  autoAddedItems: AutoAddedItem[],
  regularProductsItems: BasketItem[],
): BoundedBasketItem[] {
  const boundedProducts = autoAddedItems.map((aai) => {
    const matchingMasterProduct = regularProductsItems.find((itm) => itm.receiptId === aai.boundToItemId);

    return {
      boundedToId: matchingMasterProduct?.id ?? aai.boundToItemId, // receiptItemid in reality
      boundedToReceiptId: aai.boundToItemId,
      basketItemGuid: uuidGenerator(),
      id: aai.itemId,
      itemId: aai.productSelection.productId,
      optionId: aai.productSelection.productOptionId,
      receiptId: aai.itemId,
      quantity: aai.quantity,
      itemName: aai.productViewData?.productName ?? 'Unknown product',
      optionName: aai.productViewData?.optionName ?? 'Unknown option',
    } as BoundedBasketItem;
  });

  return boundedProducts;
}

export function createAutomaticItems(
  autoAddedItems: AutoAddedItem[],
  regularProductsItems: BasketItem[],
): BasketItem[] {
  const automaticStandaloneProducts = autoAddedItems
    .map((aai) => {
      const matchingMasterProduct = regularProductsItems.find((itm) => itm.receiptId === aai.boundToItemId);
      if (!matchingMasterProduct) {
        return {
          basketItemGuid: uuidGenerator(),
          id: aai.itemId,
          itemId: aai.productSelection.productId,
          optionId: aai.productSelection.productOptionId,
          receiptId: aai.itemId,
          quantity: aai.quantity,
          itemName: aai.productViewData.productName ?? 'Unknown item',
          optionName: aai.productViewData.optionName ?? 'Unknown option',
        } as BasketItem;
      }
      return undefined;
    })
    .filter((el) => !!el);

  return automaticStandaloneProducts as BasketItem[];
}

function updateDineInOrderWithNewItemId(
  dineInOrder: DineInOrder,
  newId: number,
  newQuantity: number,
  newItem: ItemPriceDetails,
): void {
  const dineInItem = dineInOrder.items.find((dit) => dit.id === newItem.basketItemId);

  if (dineInItem) {
    dineInItem.quantity -= newQuantity;

    const newDineInItem = {
      ...dineInItem,
      quantity: newQuantity,
      id: newId,
      receiptId: newItem.receiptItemId,
    } as DineInItem;

    dineInOrder.items.push(newDineInItem);
  }
}

export function generateBasketItemsFromDineInOrder(
  dineInOrder: DineInOrder,
  products: Product[],
  toppings: Topping[],
): BasketItem[] {
  const items =
    dineInOrder?.items.map((itm) => {
      const matchingProduct = products.find((p) => p.id === itm.itemId);
      const newBasketItem = {
        ...itm,
        doNotGroup: true,
        ignoreInPriceCalculation: itm.isCancelled,
      } as BasketItem;

      if (matchingProduct) {
        newBasketItem.itemName = matchingProduct.name;
        newBasketItem.sliceCustomizations = generateSliceCustomizations(itm, products, toppings);
      }

      return newBasketItem;
    }) ?? [];

  return items;
}

export function editMealInBasket(
  mealCoupon: MealCoupon,
  currentBasketItems: BasketItem[],
): { updatedMealCoupon: MealCoupon; updatedBasketItems: BasketItem[] } {
  const linkedBasketItemsIds: number[] = [];
  const updatedBasketItems = [...currentBasketItems];
  const updatedMealCoupon = { ...mealCoupon };
  updatedMealCoupon.meals = mealCoupon.meals.map((meal) => {
    const updatedMeal = { ...meal };
    if (meal.selectedProduct) {
      const basketItemId = getNextBasketItemId(updatedBasketItems);
      const basketItemGuid = uuidGenerator();
      updatedMeal.selectedProduct = { ...meal.selectedProduct };
      updatedMeal.selectedProduct.basketItemIndex = basketItemId;
      updatedMeal.selectedProduct.basketItemGuid = updatedMeal.selectedProduct.basketItemGuid ?? basketItemGuid;
      linkedBasketItemsIds.push(basketItemId);
      updatedBasketItems.push({
        id: basketItemId,
        basketItemGuid,
        itemId: updatedMeal.selectedProduct.baseProduct.id,
        itemName: updatedMeal.selectedProduct.baseProduct.name,
        optionId: updatedMeal.selectedProduct.originalOptionId,
        optionName: updatedMeal.selectedProduct.optionName,
        sliceCustomizations: updatedMeal.selectedProduct.sliceCustomizations,
        quantity: 1,
      } as BasketItem);
    }
    return updatedMeal;
  });
  updatedMealCoupon.linkedBaskedItemIds = linkedBasketItemsIds;

  return { updatedMealCoupon, updatedBasketItems };
}

function generateSliceCustomizations(
  dineInItem: DineInItem,
  products: Product[],
  toppings: Topping[],
): SliceCustomization[] {
  if (!dineInItem.sliceCustomizations || dineInItem.sliceCustomizations.length === 0) {
    return [];
  }

  return dineInItem.sliceCustomizations.map((sc) => {
    const matchingSliceProduct = products.find((p) => p.id === sc.sliceProductId);

    if (!matchingSliceProduct) {
      return sc;
    }

    const sliceToppingsIds = sc.toppingsConfiguration.map((tc) => tc.toppingId);
    const defaultToppingsIds = matchingSliceProduct.defaultToppings.map((dt) => dt.toppingId);
    const removedToppings = matchingSliceProduct.defaultToppings
      .filter((dt) => !sliceToppingsIds.includes(dt.toppingId))
      .map((rt) => {
        const matchingTopping = toppings.find((t) => t.id === rt.toppingId);
        return {
          ...rt,
          toppingName: matchingTopping?.name ?? '',
        } as ToppingDisplayModel;
      });
    const addedToppings = sc.toppingsConfiguration
      .filter((tc) => !defaultToppingsIds.includes(tc.toppingId))
      .map((at) => {
        const matchingTopping = toppings.find((t) => t.id === at.toppingId);
        return {
          ...at,
          toppingName: matchingTopping?.name ?? '',
        } as ToppingDisplayModel;
      });

    return {
      ...sc,
      sliceProductName: matchingSliceProduct.name,
      addedToppings,
      removedToppings,
    } as SliceCustomization;
  });
}

export function areBasketItemsTheSame(basketItem: BasketItem, basketItemToCompare: BasketItem): boolean {
  if (basketItem.itemId !== basketItemToCompare.itemId) return false;
  if (basketItem.optionId !== basketItemToCompare.optionId) return false;
  if (basketItem.remark !== basketItemToCompare.remark) return false;

  const basketItemSliceCustomizationLength = basketItem.sliceCustomizations?.length ?? 0;
  const basketItemToCompareSliceCustomizationLength = basketItem.sliceCustomizations?.length ?? 0;
  if (basketItemSliceCustomizationLength !== basketItemToCompareSliceCustomizationLength) return false;

  if (basketItemSliceCustomizationLength > 0 && basketItemToCompareSliceCustomizationLength > 0) {
    if (JSON.stringify(basketItem.sliceCustomizations) !== JSON.stringify(basketItemToCompare.sliceCustomizations))
      return false;
  }

  return true;
}
