import { StoreConfiguration } from 'typings/Store';

export enum UserLoginUX {
  REDIRECT,
  POPUP,
}

export interface IdentityServerSettings {
  url: string;
  grantType: string;
  clientId: string;
  codeChallengeMethod: string;
  responseType: string;
  scope: string[];
}

export interface TokenResult {
  access_token: string;
}

export type DecodedToken = {
  exp: number;
  sub: string;
};

export interface OnsiteResponse {
  storeId: number;
  touchpointId: string;
  isExpired: boolean;
  LastActivity: Date;
}

export interface AuthorizationState {
  processing: boolean;
  postPageRedirectStatus: 'PENDING' | 'COMPLETE' | 'ERROR';
  onsiteMachine?: OnsiteResponse;
  initialStores: StoreConfiguration[];
  machineProfiles: MachineProfile[];
  machinesLoaded?: boolean;
  storesLoading: boolean;
  onsiteMachineLoading: boolean;
  isAuthorized: boolean;
  creatingMachineStatus?: 'PENDING' | 'SUCCESS' | 'ERROR';
  createMachineError?: string;
  createdMachine?: MachineToCreate;
}

export type RegisterOnSiteMachineData = {
  storeId: number;
  touchpointId: string;
};

export type MachineToCreate = {
  machineName: string;
  machineType: MachineType;
  touchpointId: string;
  isDevelopmentMachine: boolean;
  storeId: number;
};

export interface MachineProfile {
  machineName?: string;
  isExpired: boolean;
  isDevelopmentMachine: boolean;
  lastActivity: string;
  touchpointId: string;
  storeOnSiteMachineType: string;
}

export enum MachineType {
  Registry = 1,
  Makescreen = 2,
  Dispatch = 3,
  ExecutiveDashboard = 4,
  CallCenters = 5,
  ToGo = 6,
  PackScreen = 7,
}

export interface CreateMachineResponse {
  data: any;
  succeeded: boolean;
  errorCode: string;
}
