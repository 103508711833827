import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import {
  GeneralPrintingConfiguration,
  LocalPrintingConfiguration,
  PrintingConfiguration,
} from 'containers/FirstTimeLaunch/FirstTimeLaunch';
import { RootState } from 'stores';
import { setAlert } from 'stores/Alerts';
import { isValidAxiosResponse } from 'typings/type-guards';
import { updateSettingsValidity } from './setup.slice';

export const getPrintersList = createAsyncThunk('[SETUP]/getPrintersList', async () => {
  const result = await posApi.get<string[]>(posApiUrls.CONFIGURATION_PRINTERS);

  return result.data;
});

export const saveInitialSetup = createAsyncThunk<
  void,
  {
    printingConfiguration: {
      general: GeneralPrintingConfiguration;
      local: LocalPrintingConfiguration;
    };
    storeId: string | null;
  }
>('[SETUP]/saveInitialSetup', (params, { dispatch }) => {
  return posApi.post(
    posApiUrls.INITIAL_SETUP_INITIAL,
    {
      ...params,
    },
    { headers: { Authorization: `Bearer ${localStorage.getItem('user_token')}` } },
  );
});

export const validateSettings = createAsyncThunk<void, PrintingConfiguration, { state: RootState }>(
  '[SETUP]/validateSettings',
  async (settings, { dispatch }) => {
    const result = await posApi.put<boolean>(posApiUrls.CONFIGURATION_PRINTING_VALIDATE, settings);

    if (isValidAxiosResponse(result) && result.data) {
      dispatch(
        updateSettingsValidity({
          isValid: true,
          config: settings,
        }),
      );
    } else {
      dispatch(
        updateSettingsValidity({
          isValid: false,
          config: settings,
        }),
      );
      dispatch(setAlert({ header: 'One of specified files does not exist', variant: 'error' }));
    }
  },
);

export const getPrintingConfiguration = createAsyncThunk('[CONFIG]/getPrintingConfiguration', async () => {
  const result = await posApi.get<PrintingConfiguration>(posApiUrls.CONFIGURATION_PRINTING);

  return result.data;
});

export const savePrintingConfiguration = createAsyncThunk<void, PrintingConfiguration, { state: RootState }>(
  '[SETUP]/savePrintingSettings',
  async (settings, { dispatch }) => {
    const result = await posApi.post<boolean>(posApiUrls.CONFIGURATION_PRINTING, {
      printingConfiguration: {
        ...settings,
      },
    });

    if (isValidAxiosResponse(result)) {
      if (result.status === 200) {
        dispatch(setAlert({ header: 'Printing configuration saved', variant: 'success' }));
      } else {
        dispatch(setAlert({ header: 'One of specified files does not exist', variant: 'error' }));
      }
    } else {
      dispatch(setAlert({ header: 'Error saving configuration', variant: 'error' }));
    }
  },
);
