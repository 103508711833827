import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, transitions, spacing }) => {
  return {
    root: {
      width: spacing(6),
      height: spacing(3.25),
      padding: 0,
      margin: spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(14px)',
        color: palette.secondary.light,
        '& + $track': {
          backgroundColor: palette.primary.main,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: palette.primary.main,
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: spacing(3),
      height: spacing(3),
    },
    track: {
      borderRadius: spacing(3 / 2),
      border: `1px solid ${palette.grey[400]}`,
      backgroundColor: palette.grey[100],
      opacity: 1,
      transition: transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  };
});

export default buildClasses;
