export const colors = {
  green: {
    50: '#F6FFF9',
    100: '#EEFFF3',
    200: '#D2FFE0',
    300: '#98EEB1',
    400: '#6EDD8F',
    500: '#59D27D',
    600: '#44C469',
    700: '#3EB561',
    800: '#2F994E',
    900: '#217E3C',
  },
  blue: {
    50: '#EBF5FF',
    100: '#DBEDFF',
    200: '#BBDAFF',
    300: '#8DC2FF',
    400: '#62AAFF',
    500: '#288BFF',
    600: '#006DEC',
    700: '#005FCF',
    800: '#0051B0',
    900: '#00418E',
  },
  red: {
    50: '#FFF6F6',
    100: '#FFEBEB',
    200: '#FFDCDC',
    300: '#FFC5C5',
    400: '#FF9898',
    500: '#FF6A6A',
    600: '#FF5050',
    700: '#FF003D',
    800: '#DA0236',
    900: '#BA0631',
  },
  yellow: {
    50: '#FFFDE7',
    100: '#FDF9C6',
    200: '#FCF4A1',
    300: '#FBF07E',
    400: '#FAEE64',
    500: '#F3D749',
    600: '#ECBE3F',
    700: '#E5A737',
    800: '#DD9006',
    900: '#CD711B',
  },
  labels: {
    50: '#FFFAC9',
    100: '#FFEDD8',
    200: '#FFDCDC',
    300: '#FFDCFB',
    400: '#DCDDFF',
    500: '#CCE7FF',
    600: '#CCFCFF',
    700: '#CCFFE7',
    800: '#D0FFCC',
    900: '#EDF0F1',
  },
  grey: {
    50: '#F5F5F5',
    100: '#EEEEEE',
    200: '#EAEAEA',
    300: '#C1CACE',
    400: '#AEB9BF',
    500: '#A9A9A9',
    600: '#989898',
    700: '#5E6A74',
    800: '#555555',
    900: '#2C3539',
  },
  orderStatus: {
    toPrepare: '#FFCD42',
    inPreparation: '#FF7A30',
    outForDelivery: '#288BFF',
    inDelivery: '#CF45FF',
    completed: '#44C369',
    canceled: '#97A5AC',
  },
};
