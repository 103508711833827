import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { OrderPaymentDetails } from 'stores/OrderPayment/orderPayment.slice';
import { Company } from 'typings/Customer';

export interface PaymentsState {
  ctmpCommandStatus?: 'PENDING' | 'SUCCESS' | 'FAILED';
  paymentCancelled?: boolean;
  cashDrawerOpened?: boolean;
  availablePaymentMethods: PaymentMethod[];
  settlePayment?: SettlePaymentState;
  unpaidOrderPlacementInProgress?: boolean;
  terminalLastStatus?: EftPaymentTerminalStatus;
  paymentRefundAttemptStatus?: OnlinePaymentRefundAttemptStatus;
}

export interface EftState {
  eftPaymentTerminal?: EftPaymentTerminal | 'not-configured';
  eftPaymentId?: string;
  error?: string;
  success?: boolean;
  cancelInProgress?: boolean;
  startTransactionInProgress?: boolean;
}

export enum EftError {
  EftConnectionFailure = 'EftConnectionFailure',
  EftError = 'EftError',
  EftCancelled = 'EftCancelled',
  EftResultUnknown = 'EftResultUnknown',
  EftInterrupted = 'EftInterrupted',
}

export interface PaymentSuccessfulResult {
  orderId: string;
  paymentMethodCode: PaymentMethodCode;
  openTabId?: string;
  eftPaymentOperation?: EftPaymentOperation;
  orderAlreadyPlaced?: boolean;
  chargedCompany?: Company;
}

export interface StartPaymentResult {
  orderId: string;
  paymentMethod: PaymentMethodCode;
  eftPaymentId?: string;
  openTabId?: string;
  orderAlreadyPlaced?: boolean;
}
export interface SettlePaymentState {
  successCallback: (result: PaymentSuccessfulResult) => void;
  closeCallback: () => void;
  startPaymentCallback?: (result: StartPaymentResult) => void;
  totalToPay: number;
  orderId: string;
  openTabId?: string;
  unpaidOrderPlaced?: boolean;
  useTwoStepOrderPlacing?: boolean;
  payOnAccountAvailable?: boolean;
  forcePaymentMethodType?: PaymentMethodCode; // to delete with V1
  forcedPaymentMethodCode?: PaymentMethodCode;
  chargedCompanyWhenPayingOnAccount?: Company;
  deliveryType?: PickUpTypesValues;
  orderPaymentDetails?: OrderPaymentDetails;
  orderFinalizationData?: CheckoutDetailsForm;
}
export interface StartEftPaymentResult {
  success: boolean;
  errorMessage?: string;
}

export interface RequestEftPaymentResult {
  success: boolean;
  errorCode: string;
}

export interface EftPaymentSettings {
  statusPollingIntervalInSeconds: number;
  manualFinishEnabledDelayInSeconds: number;
  hideManualFinishButton: boolean;
}

export interface EftPaymentTerminal {
  deviceId: number;
  name: string;
  canStartCTMP: boolean;
}

export interface EftPaymentTerminalStatus {
  statusReport: { [key: string]: DeviceInternalStatusEntry };
  additionalInfo: string;
}

export interface RefundPaymentAttemptResult {
  succeded: boolean;
}

export interface DeviceInternalStatusEntry {
  value: string;
  statusType: DeviceInternalStatusType;
}

export enum DeviceInternalStatusType {
  Correct = 'Correct',
  Incorrect = 'Incorrect',
  Warning = 'Warning',
  Info = 'Info',
}

export interface EftPaymentOperation {
  eftPaymentId?: string;
  deviceId?: string;
  finishedManually: boolean;
}

export interface GetEftPaymentStatusResult {
  status: ExecutionResultStatus;
  responseMessage?: string;
  responseDetailCode?: ExecutionResultDetailCode;
}

export interface GetEftPaymentRefundStatusResult {
  status: OnlinePaymentRefundStatus;
  errorMessage?: string;
}

export enum OnlinePaymentRefundAttemptStatus {
  InProgress = 'InProgress',
  Succeded = 'Succeded',
  Failed = 'Failed',
}

export interface PaymentMethod {
  id: number;
  code: PaymentMethodCode;
  platformId: string;
  description: string;
  supportsEftPayment: boolean;
  methodRemark?: string;
  sortOrder: number;
  groupName?: string;
}
export interface GetPaymentMethodsRequest {
  pickupType: PickUpTypesValues;
  storeId?: number;
}

export enum PaymentMethodCode {
  Cash = 'Contant',
  Card = 'Pinnen',
  ManualIdeal = 'ManualIdeal',
  MobielePin = 'MOBIELE PIN',
  GiftCard = 'Giftcard',
  // most probably this payment method enum should not be used
  // we are getting this coda also from 'paymentMethodsConfiguration?.payOnAccountMethodCode'
  PayOnAccount = 'PayOnAccount',

  // helper code (artificial one) for pickup payment method choosing
  // (only pay on account works there)
  PayInStoreArtificial = 'PayInStore',

  GiftcardQr = 'GiftCardQr',
}

export enum OnlinePaymentRefundStatus {
  Initial = 'Initial',
  None = 'None',
  RefundSucceeded = 'RefundSucceeded',
  RefundFailed = 'RefundFailed',
}

export enum ExecutionResultStatus {
  Pending = 'Pending',
  Succeeded = 'Succeeded',
  WithError = 'WithError',
  Canceled = 'Canceled',
  ResultUnknown = 'ResultUnknown',
  Interrupted = 'Interrupted',
}

export enum ExecutionResultDetailCode {
  Cancelled = 'Cancelled',
  StoppedByDevice = 'StoppedByDevice',
}

export enum PaymentTarget {
  Order = 'Order',
  OpenTab = 'OpenTab',
}

export interface AcceptManualPaymentRequest {
  paymentId: string;
  orderId: string;
  isLocalOrder: boolean;
  paymentMethodId: number;
  orderAmount: number;
  tipAmount: number;
}

export interface FinalizePaidOrderRequest {
  orderId: string;
  isLocalOrder: boolean;
}

export interface FinalizationTimeData {
  finalizationTime?: number;
  skipFinalizationScreen: boolean;
}
