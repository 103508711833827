import { CSSObject } from '@mui/material/styles';

import { BASE_THEME } from './base.theme.css';

const BAKKER_COLORS = {
  ...BASE_THEME.palette,
  primary: { main: '#cb007c', light: '#D40E8C', dark: '#AB006D', contrastText: '#fff' },
  secondary: {
    ...BASE_THEME.palette.secondary,
    main: '#ededed',
  },
  error: {
    ...BASE_THEME.palette.error,
    main: '#D9534F',
    dark: '#D9534F',
    light: '#D9534F',
  },
  success: {
    light: '#db4da3',
    main: '#D40E8C',
    dark: '#AB006D',
    contrastText: '#fff',
  },
};

export const BAKKER = {
  ...BASE_THEME,
  palette: {
    ...BAKKER_COLORS,
    virtualReceipt: {
      items: {
        discount: {
          ...BASE_THEME.palette.virtualReceipt.items.discount,
          error: {
            icon: 'rgb(255,41,41)',
            background: BAKKER_COLORS.error.light,
          },
        },
      },
    },
  },
  components: {
    ...BASE_THEME.components,
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: `#cb007c`,
        },
        arrow: {
          color: `#fff`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        colorSecondary: {
          color: 'white',
        },
      },
    },
  },
};
