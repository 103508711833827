import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CashierState, LoginStates, TimekeepingResult } from 'typings/Cashier';
import {
  clockInCashier,
  clockOutCashier,
  getClockedInUsers,
  getLoggedInCashier,
  logInCashier,
  logOutCashier,
  revalidatePin,
  getEmployeeCashBalance,
} from 'stores/Cashier/cashier.thunk-actions';
import { AxiosError } from 'axios';

const initialState: CashierState = {
  checkingCashierInProgress: false,
  loginState: LoginStates.enterPin,
  operationInProgress: undefined,
  clockedInEmployees: [],
};

const cashierSlice = createSlice({
  name: '[CASHIER]',
  initialState,
  reducers: {
    resetCashierState: (state) => {
      return {
        ...initialState,
        loggedCashier: state.loggedCashier,
      };
    },
    setLoginState: (state, action: PayloadAction<LoginStates>) => ({
      ...state,
      loginState: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(logInCashier.pending, (state) => {
      return { ...state, operationInProgress: 'unlock' };
    });
    builder.addCase(logInCashier.fulfilled, (state, action) => {
      return {
        ...state,
        loginState: LoginStates.cashierLoginSuccess,
        loggedCashier: {
          id: action.payload.userId,
          firstName: action.payload.givenName,
          lastName: action.payload.familyName,
          permissions: [],
        },
        operationInProgress: undefined,
      };
    });
    builder.addCase(logInCashier.rejected, (state) => {
      return { ...state, operationInProgress: undefined, loginState: LoginStates.error };
    });
    builder.addCase(logOutCashier.fulfilled, (state) => {
      return { ...state, loggedCashier: undefined, operationInProgress: undefined };
    });
    builder.addCase(logOutCashier.rejected, (state) => {
      return { ...state, loggedCashier: undefined, operationInProgress: undefined };
    });

    builder.addCase(clockInCashier.pending, (state) => {
      return { ...state, operationInProgress: 'clockIn' };
    });
    builder.addCase(clockInCashier.fulfilled, (state, action) => {
      return {
        ...state,
        loginState: LoginStates.clockInSuccess,
        timekeepingOperationUser: {
          id: action.payload.userId,
          firstName: action.payload.givenName,
          lastName: action.payload.familyName,
          permissions: [],
        },
        operationInProgress: undefined,
      };
    });
    builder.addCase(clockInCashier.rejected, (state, action) => {
      return {
        ...state,
        operationInProgress: undefined,
        loginState: LoginStates.timekeepingError,
        errorCode: ((action.payload as AxiosError).response?.data as TimekeepingResult)?.errorCode,
      };
    });

    builder.addCase(clockOutCashier.pending, (state) => {
      return { ...state, operationInProgress: 'clockOut' };
    });
    builder.addCase(clockOutCashier.fulfilled, (state, action) => {
      return {
        ...state,
        loginState: LoginStates.clockOutSuccess,
        timekeepingOperationUser: {
          id: action.payload.userId,
          firstName: action.payload.givenName,
          lastName: action.payload.familyName,
          permissions: [],
        },
        operationInProgress: undefined,
      };
    });
    builder.addCase(clockOutCashier.rejected, (state, action) => {
      return {
        ...state,
        loginState: (action.payload as { employeeId: string | undefined }).employeeId
          ? LoginStates.cashflowConfirmationError
          : LoginStates.timekeepingError,
        operationInProgress: undefined,
        errorCode: ((action.payload as AxiosError).response?.data as TimekeepingResult)?.errorCode,
      };
    });
    builder.addCase(getLoggedInCashier.pending, (state) => {
      return {
        ...state,
        checkingCashierInProgress: true,
      };
    });
    builder.addCase(getLoggedInCashier.rejected, (state) => {
      return {
        ...state,
        loggedCashier: undefined,
        checkingCashierInProgress: false,
      };
    });
    builder.addCase(getLoggedInCashier.fulfilled, (state, action) => {
      return {
        ...state,
        checkingCashierInProgress: false,
        loggedCashier: {
          id: action.payload.userId,
          firstName: action.payload.givenName,
          lastName: action.payload.familyName,
          roleName: action.payload.mainRole,
          permissions: action.payload.permissions,
        },
      };
    });
    builder.addCase(getEmployeeCashBalance.pending, (state, _) => {
      return {
        ...state,
        operationInProgress: 'clockOut',
      };
    });
    builder.addCase(getEmployeeCashBalance.rejected, (state, action) => {
      return {
        ...state,
        loginState: LoginStates.timekeepingError,
        operationInProgress: undefined,
        errorCode: ((action.payload as AxiosError).response?.data as TimekeepingResult)?.errorCode,
      };
    });

    builder.addCase(getEmployeeCashBalance.fulfilled, (state, action) => {
      return {
        ...state,
        operationInProgress: undefined,
        loginState: action.payload ? LoginStates.cashFlow : state.loginState,
        employeeCashBalanceInfo: action.payload ? action.payload : undefined,
      };
    });

    builder.addCase(getClockedInUsers.fulfilled, (state, action) => {
      return {
        ...state,
        clockedInEmployees: action.payload ?? [],
      };
    });

    builder.addCase(revalidatePin.fulfilled, (state, action) => {
      return {
        ...state,
        revalidationResult: action.payload,
        operationInProgress: undefined,
      };
    });
    builder.addCase(revalidatePin.pending, (state) => {
      return { ...state, operationInProgress: 'revalidate' };
    });
    builder.addCase(revalidatePin.rejected, (state, action) => {
      return {
        ...state,
        revalidationResult: {
          success: false,
          revalidateFor: action.payload as string,
        },
        operationInProgress: undefined,
      };
    });
  },
});

const { reducer } = cashierSlice;
export const { setLoginState, resetCashierState } = cashierSlice.actions;
export default reducer;
