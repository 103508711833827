import { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { Coupon, MealCoupon } from './Coupons';

type responseType<T> = AxiosResponse<T> | AxiosError;

export function isValidAxiosResponse<T>(response: responseType<T>): response is AxiosResponse<T> {
  if ('isAxiosError' in response) {
    return false;
  }
  return true;
}

export function isValidSuccessfulAxiosResponse<T>(response: responseType<T>): response is AxiosResponse<T> {
  if ('isAxiosError' in response) return false;
  if (!_.inRange(response.status, 200, 299)) return false;
  return true;
}

export function isMealCoupon(coupon: MealCoupon | Coupon): coupon is MealCoupon {
  if ('meals' in coupon) {
    return true;
  }
  return false;
}
