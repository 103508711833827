import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { clearModal } from 'stores/Alerts';
import ModalContainer from 'components/Shared/Modal/ModalContainer';

const AlertModal = () => {
  const { modal } = useAppSelector((state) => state.alerts);

  const dispatch = useAppDispatch();
  const { t: errorCodeTranslation } = useTranslation('errorCodes');
  const { t } = useTranslation('alerts');

  if (!modal) return <></>;

  const { header, message, messageAddon } = modal;

  const translatedMessage = message ? errorCodeTranslation(message) : t('Please contact support');

  return (
    <ModalContainer
      onClose={() => {
        dispatch(clearModal());
      }}
      title={t(header)}
      open
      testId="text-field-modal"
      primaryButton={{
        label: t('OK'),
        testId: 'information-modal__button--ok',
        type: 'submit',
        action: () => {
          dispatch(clearModal());
        },
      }}
    >
      <Box
        sx={{ width: '100%', minHeight: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        {translatedMessage && <Typography variant="subtitle1">{translatedMessage}</Typography>}
        {messageAddon && <Typography variant="subtitle2">{t(messageAddon)}</Typography>}
      </Box>
    </ModalContainer>
  );
};

export default AlertModal;
