import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HybridModeMessages } from 'typings/HybridMode';
import { getHybridModeCommunicator } from 'utils/hybridMode/hybridModeCommunicationUtils';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { useAppSelector } from 'hooks/useRedux';
import { getIntakeStateToTransfer } from 'stores/HybridMode/hybridMode.selector';
import { useTheme } from '@mui/system';

interface ConnectionReestablishedDialogProps {
  open: boolean;
}

const ConnectionReestablishedDialog: React.FC<ConnectionReestablishedDialogProps> = ({ open }) => {
  const [closed, setClosed] = React.useState(false);
  const { palette } = useTheme();
  const [t] = useTranslation('common');
  const intakeStateToTransfer = useAppSelector(getIntakeStateToTransfer());

  function switchToCloudPos() {
    const hybridModeCommunicator = getHybridModeCommunicator();

    hybridModeCommunicator.send(HybridModeMessages.Hybrid.Commands.SwitchToCloudPos, intakeStateToTransfer);
  }

  function stayOffline() {
    setClosed(true);
  }
  const textStyle = { color: palette.black.main, margin: 2, textAlign: 'left' };

  function additionalInfoSection(): JSX.Element {
    if (intakeStateToTransfer) {
      return (
        <Typography variant="body1" textAlign="center" sx={textStyle}>
          {t('Basket will be transferred')}
        </Typography>
      );
    }

    return (
      <Typography variant="body1" textAlign="center" sx={textStyle}>
        <b>{t('Please notice!')}</b> {t('Mode switch warning')}
      </Typography>
    );
  }

  const additionalInfo = additionalInfoSection();

  return (
    <ModalContainer
      onClose={stayOffline}
      open={open && !closed}
      title={t('Connection restored')}
      primaryButton={{
        testId: 'switch-to-online-confirm',
        label: t('Switch to online'),
        action: switchToCloudPos,
      }}
      secondaryButton={{
        label: t('Stay offline'),
        testId: 'switch-store-modal-cancel',
        action: stayOffline,
      }}
      testId="switch-to-online-modal"
    >
      <Typography variant="body1" textAlign="center" sx={textStyle}>
        {t('Do you want to swap back to online mode?')}
      </Typography>
      {additionalInfo}
    </ModalContainer>
  );
};
export default ConnectionReestablishedDialog;
