/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CmsCarouselBanner,
  CmsCustomerDisplay,
  CmsLanguage,
  CmsPosImages,
  fetchCustomerDisplayMedia,
  fetchPOSImages,
  fetchSupportedLanguages,
} from 'API/CmsApi';
import i18n from 'i18n';
import { initialState, SupportedUILanguage } from 'stores/Cms/cms.interface';

export const fetchLanguages = createAsyncThunk<CmsLanguage[]>('cms/languages', async (_, { rejectWithValue }) => {
  try {
    const { data } = await fetchSupportedLanguages();
    const currentLang = i18n.language;
    if (!data.some((el) => el.code === currentLang)) {
      const defaultLang = data.find((el) => el.isDefault)?.code ?? data[0].code;
      i18n.changeLanguage(defaultLang);
    }
    return data;
  } catch (err: any) {
    return err instanceof Error ? rejectWithValue(err.message) : rejectWithValue(err.toString());
  }
});
export const fetchPosImages = createAsyncThunk<CmsPosImages>('cms/pos-images', async (_, { rejectWithValue }) => {
  try {
    const { data } = await fetchPOSImages();
    return data;
  } catch (err: any) {
    return err instanceof Error ? rejectWithValue(err.message) : rejectWithValue(err.toString());
  }
});

export const fetchCustomerDisplay = createAsyncThunk<CmsCustomerDisplay>(
  'cms/customer-display-media',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await fetchCustomerDisplayMedia();
      return data;
    } catch (err: any) {
      return err instanceof Error ? rejectWithValue(err.message) : rejectWithValue(err.toString());
    }
  },
);

export const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setLang(state, { payload }: PayloadAction<SupportedUILanguage>) {
      state.languages.push(payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLanguages.fulfilled, (state, { payload }) => {
      const supportedLanguages = payload.map((cmsLang) => {
        return {
          code: cmsLang.code,
          name: cmsLang.name,
          isDefault: cmsLang.isDefault,
        } as SupportedUILanguage;
      });
      return { ...state, languages: supportedLanguages };
    });

    builder.addCase(fetchPosImages.fulfilled, (state, { payload }) => {
      return { ...state, loginScreen: payload.splashScreen, logo: payload.logo, bestSeller: payload.bestSeller };
    });
    builder.addCase(fetchCustomerDisplay.fulfilled, (state, { payload }) => {
      return {
        ...state,
        customerDisplayBasket: payload.basketImage,
        customerDisplaySplashScreen: payload.splashScreen,
        customerDisplayHeaderIcon: payload.headerIcon,
      };
    });
  },
});

export const { setLang } = slice.actions;

export default slice.reducer;
