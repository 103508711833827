import { CmsCarouselBanner } from 'API/CmsApi';

export interface CmsState {
  languages: SupportedUILanguage[];
  activeLang: string;
  loginScreen?: MediaComponent;
  logo?: MediaComponent;
  customerDisplaySplashScreen?: CmsCarouselBanner;
  customerDisplayBasket?: CmsCarouselBanner;
  bestSeller?: MediaComponent;
  customerDisplayHeaderIcon?: MediaComponent;
}

export const initialState: CmsState = {
  languages: getBaseLanguages(),
  activeLang: 'nl',
};

export interface SupportedUILanguage {
  code: string;
  name: string;
  isDefault?: boolean;
}
export interface MediaComponent {
  name?: string;
  ext?: string;
  url?: string;
}

function getBaseLanguages(): SupportedUILanguage[] {
  return [
    {
      code: 'nl',
      name: 'Dutch',
      isDefault: true,
    },
    {
      code: 'en',
      name: 'English',
    },
    {
      code: 'pl',
      name: 'Polish',
    },
    {
      code: 'es',
      name: 'Spanish',
    },
  ];
}
