import { Theme } from '@mui/material/styles';
import brandLogo from 'assets/logos/PJ/logo.svg';

import { BASE_THEME } from './base.theme.css';

const PAPAJOHN_COLORS = {
  primary: {
    main: '#2D5D2A',
    light: '#43784A',
    dark: '#1b3819',
    contrastText: '#fff',
  },
  secondary: {
    ...BASE_THEME.palette.secondary,
  },
  error: {
    ...BASE_THEME.palette.error,
    main: '#D9534F',
  },
  success: {
    light: '#81c784',
    main: '#4caf50',
    dark: '#388e3c',
    contrastText: '#fff',
  },
};

export const PAPAJOHN = {
  ...BASE_THEME,
  brandLogo,
  loginImage: {
    url: brandLogo,
    backgroundSize: 'contain',
  },
  palette: {
    ...BASE_THEME.palette,
    ...PAPAJOHN_COLORS,

    grey: {
      ...BASE_THEME.palette.grey,
      '100': '#EEE',
      '200': '#EAEAEA',
      '500': '#A9A9A9',
      '600': '#8E8E8E',
    },
    appBar: {
      main: '#43784A',
      subMain: '#5A936A',
      subMainText: '#fff',
    },
  },
  virtualReceipt: {
    items: {
      discount: {
        ...BASE_THEME.palette.virtualReceipt.items.discount,
        error: {
          icon: 'rgb(255,41,41)',
          background: PAPAJOHN_COLORS.error.light,
        },
      },
    },
  },
} as Theme;
