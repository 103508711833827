import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { RootState } from 'stores';

import { isValidAxiosResponse, isValidSuccessfulAxiosResponse } from 'typings/type-guards';
import {
  GiftCardActivationAttempt,
  GiftCardActivationDto,
  GiftCardActivationStatus,
} from 'typings/Payments/giftCardTypes';
import { AxiosError } from 'axios';
import { RequestEftPaymentResult } from 'typings/Payments';
import { OrderPaymentDetails } from 'stores/OrderPayment/orderPayment.slice';
import { ActivationStep } from 'stores/GiftCardActivation/giftCardActivation.slice';

export const checkOrderGiftCards = createAsyncThunk<
  { cards: GiftCardActivationAttempt[]; orderId: string; details: OrderPaymentDetails },
  { orderId: string; isLocalOrder?: boolean; details: OrderPaymentDetails },
  { state: RootState }
>('[GiftCard]/checkOrderGiftCards', async ({ orderId, isLocalOrder, details }, { rejectWithValue }) => {
  const giftCardActivationDto = await posApi.get<GiftCardActivationDto>(posApiUrls.GIFT_CARDS_CHECK, {
    params: { orderId, isLocalOrder },
  });

  if (!isValidSuccessfulAxiosResponse(giftCardActivationDto)) {
    throw new Error(`Gift card activation could not be get for orderId=${orderId}`);
  }

  const allCardsSucceeded = giftCardActivationDto.data.giftCards.every(
    (el) => el.status === GiftCardActivationStatus.Succeeded,
  );

  if (allCardsSucceeded) {
    rejectWithValue('AllCardsSuccessfully activated for given order');
  }

  return {
    cards: giftCardActivationDto?.data?.giftCards ?? [],
    orderId,
    details,
  };
});

export const activateGiftCard = createAsyncThunk<
  string,
  { giftCardActivationId: string; cardNumber: string; onSuccess?: () => void },
  { state: RootState }
>(
  '[GiftCard]/activateGiftCard',
  async ({ giftCardActivationId, cardNumber, onSuccess }, { dispatch, rejectWithValue }) => {
    const result = await posApi
      .put<RequestEftPaymentResult>(
        posApiUrls.GIFT_CARD_ACTIVATE(giftCardActivationId),
        {},
        { params: { number: cardNumber } },
      )
      .catch((err: AxiosError<{ errorCode: string }>) => err);

    if (!isValidAxiosResponse(result)) {
      return rejectWithValue(result.response?.data.errorCode ?? 'Error activating gift card');
    }

    await dispatch(checkSpecifiedGiftCard({ giftCardId: giftCardActivationId }));
    onSuccess && onSuccess();
    return giftCardActivationId;
  },
);

export const checkSpecifiedGiftCard = createAsyncThunk<
  GiftCardActivationAttempt,
  { giftCardId: string },
  { state: RootState }
>('[GiftCard]/checkSpecifiedGiftCard', async ({ giftCardId }, { dispatch, getState }) => {
  const giftCardActivationStatus = await posApi.get<GiftCardActivationAttempt>(
    posApiUrls.GIFT_CARD_CHECK(giftCardId),
  );

  if (!isValidSuccessfulAxiosResponse(giftCardActivationStatus)) {
    throw new Error(`Cannot get gift card activation status`);
  }

  return giftCardActivationStatus.data;
});

export function getActivationStep(attempt: GiftCardActivationAttempt): ActivationStep {
  if (attempt.status === GiftCardActivationStatus.Failed) return 'failure';
  if (attempt.status === GiftCardActivationStatus.Succeeded) return 'success';
  return 'enterData';
}
