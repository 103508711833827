import usePrevious from 'hooks/usePrevious';
import { useAppSelector } from 'hooks/useRedux';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { analyzeRecalculatedBasket, FiscalEvent, FiscalSummary } from 'utils/applicationContainer/fiscalUtils';
import uuidGenerator from 'utils/GuidGenerator';

const FiscalContext = createContext<FiscalContextType>({
  currentFiscalSummary: { fiscalContextId: uuidGenerator(), fiscalEvents: [] },
});

type FiscalContextType = {
  currentFiscalSummary: FiscalSummary;
};

const FiscalContextProvider: React.FC = ({ children }) => {
  const [fiscalContextId, setFiscalContextId] = useState<string>(uuidGenerator());
  const [fiscalEvents, setFiscalEvents] = useState<FiscalEvent[][]>([[]]);
  const { basketData, lastBasketClearTimestamp, basketItems } = useAppSelector((state) => state.basket);
  const previousBasket = usePrevious(basketData);
  const previousBasketItems = usePrevious(basketItems);

  const contextData: FiscalContextType = {
    currentFiscalSummary: {
      fiscalContextId,
      fiscalEvents: fiscalEvents.filter((el) => el.length > 0),
    },
  };

  const dataPersisted = useMemo(() => contextData, [fiscalContextId, fiscalEvents]);

  useEffect(() => {
    const analyzedEvents = analyzeRecalculatedBasket(
      basketData,
      previousBasket,
      basketItems,
      previousBasketItems ?? [],
    );
    setFiscalEvents([...fiscalEvents, analyzedEvents]);
  }, [basketData]);

  useEffect(() => {
    setFiscalEvents([]);
    setFiscalContextId(uuidGenerator());
  }, [lastBasketClearTimestamp]);

  return <FiscalContext.Provider value={dataPersisted}>{children}</FiscalContext.Provider>;
};

export { FiscalContext, FiscalContextProvider };
