export default function timeSpanToSeconds(timeSpan: string): number {
  // assume that TimeSpan is hh:mm:ss
  const values = timeSpan.split(':');

  const minFromHours = parseFloat(values[0]) * 3600;
  const minFromMinutes = parseFloat(values[1]) * 60;
  const minFromSeconds = parseFloat(values[2]);

  return Math.round(minFromHours + minFromMinutes + minFromSeconds);
}

export function timeSpanToMinutes(timeSpan: string | null): number {
  if (!timeSpan || timeSpan.length === 0) return 0;
  return Math.floor(timeSpanToSeconds(timeSpan) / 60);
}

export function minutesToTimeSpan(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${hours < 10 ? '0' : ''}${hours}:${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}:00`;
}

export function formatDateToDisplay(value: Date, culture: string, timeZone?: string): string {
  if (timeZone) {
    return value.toLocaleDateString(culture, { timeZone });
  }
  return value.toLocaleDateString(culture);
}

export function formatDateTimeToDisplay(value: Date, culture: string, timeZone?: string): string {
  if (timeZone) {
    return `${value.toLocaleDateString(culture, { timeZone })} ${value.toLocaleTimeString(culture, { timeZone })}`;
  }

  return `${value.toLocaleDateString(culture)} ${value.toLocaleTimeString(culture)}`;
}

export function formatTimeToDisplay(value: Date, culture: string, timeZone?: string): string {
  if (timeZone) {
    return value.toLocaleTimeString(culture, { timeZone });
  }
  return value.toLocaleTimeString(culture);
}
export function isDeliveryDateToday(date: string): boolean {
  const today = new Date();
  const deliveryDate = new Date(date);

  return today.toDateString() === deliveryDate.toDateString();
}
