import { createSlice } from '@reduxjs/toolkit';
import { clearOrderEdition } from 'stores/combined.actions';
import { EmbeddedModeState } from './embeddedMode.types';
import { initEmbeddedModeOrderEdition } from './embeddedMode.thunk-actions';

const initialState = {} as EmbeddedModeState;

const embeddedModeSlice = createSlice({
  name: '[EMBEDDED_MODE]',
  initialState,
  reducers: {
    clearEmbeddedModeOrderEdit: (state) => ({
      ...state,
      embeddedModeOrderEdition: undefined,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(initEmbeddedModeOrderEdition.fulfilled, (state, action) => ({
      ...state,
      embeddedModeOrderEdition: action.payload,
    }));
    builder.addCase(clearOrderEdition.fulfilled, (state) => ({
      ...state,
      embeddedModeOrderEdition: undefined,
    }));
  },
});

const { reducer } = embeddedModeSlice;
export const { clearEmbeddedModeOrderEdit } = embeddedModeSlice.actions;

export default reducer;
