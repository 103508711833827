import { AnyAction, ThunkAction, configureStore } from '@reduxjs/toolkit';
import dineIn from './DineIn/dineIn.slice';
import intake from './Intake/intake.slice';
import localOrders from './LocalOrders/localOrders.slice';
import authorization from './Auth/auth.slice';
import config from './Config/config.slice';
import floors from './Floors/floors.slice';
import SignalRMiddleware from './Notifications/signalRMiddleware';
import notifications from './Notifications/notifications.slice';
import customer from './Customer/customer.slice';
import stores from './Store/store.slice';
import alerts from './Alerts/alerts.slice';
import products from './Products/products.slice';
import coupons from './Coupons/coupons.slice';
import basket from './Basket/basket.slice';
import payments from './Payments/payments.slice';
import eft from './Eft/eft.slice';
import cashier from './Cashier/cashier.slice';
import hybridMode from './HybridMode/hybridMode.slice';
import setup from './Setup/setup.slice';
import allOrders from './AllOrders/allOrders.slice';
import customerDisplay from './CustomerDisplay/customerDisplay.slice';
import cms from './Cms/cms.slice';
import orderPayment from './OrderPayment/orderPayment.slice';
import embeddedMode from './EmbeddedMode/embeddedMode.slice';
import eftPayment from './EftPayment/eftPayment.slice';
import toppings from './Toppings/toppings.slice';
import giftCards from './GiftCardActivation/giftCardActivation.slice';
import loyalty from './Loyalty/loyalty.slice';

const store = configureStore({
  reducer: {
    basket,
    products,
    coupons,
    intake,
    dineIn,
    customer,
    localOrders,
    config,
    stores,
    authorization,
    notifications,
    floors,
    alerts,
    payments,
    eft,
    cashier,
    hybridMode,
    setup,
    allOrders,
    customerDisplay,
    cms,
    orderPayment,
    embeddedMode,
    eftPayment,
    toppings,
    giftCards,
    loyalty,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // TODO remove and search for all non-serializable data passed to redux and provide fixes
    }).concat(SignalRMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppAction = ThunkAction<void, RootState, undefined, AnyAction>;
export type AppDispatch = typeof store.dispatch;

export default store;
