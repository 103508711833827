import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddressBookEntry, ProfileAddress } from 'stores/Intake/intakeTypes';
import CustomerState, { Company } from 'typings/Customer';
import {
  checkCurrentCustomerCanPayOnAccount,
  checkSpecifiedCustomerCanPayOnAccount,
  getCustomerAddresses,
  getCustomerAddressesByPhone,
  getCustomerCreditDetails,
  getCustomerSearchResults,
} from './customer.thunk-actions';

const initialState: CustomerState = {
  customerCanPayOnAccount: false,
  customerAddresses: [],
  addressesLoading: false,
  loadingCustomerSearchResults: false,
  customerSearchResults: [],
  noMoreResults: false,
};

const customerSlice = createSlice({
  name: '[CUSTOMER]',
  initialState,
  reducers: {
    restartCustomerState: () => initialState,
    clearSearchResults(state) {
      return { ...state, customerSearchResults: [] };
    },
    setCustomerCanPayOnAccount(state, action: PayloadAction<boolean>) {
      return { ...state, customerCanPayOnAccount: action.payload };
    },
    setCustomerAddresses(state, action: PayloadAction<AddressBookEntry[]>) {
      return { ...state, customerAddresses: action.payload };
    },
    setSelectedCustomerAddress(state, action: PayloadAction<ProfileAddress>) {
      const newCustomerAddresses = state.customerAddresses.map((ca) => {
        return { ...ca, isSelected: ca.fullAddress === action.payload.fullAddress };
      });
      return { ...state, customerAddresses: newCustomerAddresses };
    },
    clearSelectedCustomerAddress(state) {
      const newCustomerAddresses = state.customerAddresses.map((ca) => {
        return { ...ca, isSelected: false };
      });
      return { ...state, customerAddresses: newCustomerAddresses };
    },
    clearCustomerData(state) {
      return {
        ...state,
        customerAddresses: [],
        customerCanPayOnAccount: false,
        chargedCompany: undefined,
        customerCreditToUse: undefined,
      };
    },
    clearCustomerCreditUsage(state) {
      return { ...state, customerCreditToUse: undefined };
    },
    clearCustomerCreditDetails(state) {
      return { ...state, customerCreditDetails: undefined };
    },
    setUseCustomerCredits(state, action: PayloadAction<number | undefined>) {
      return { ...state, customerCreditToUse: action.payload };
    },
  },
  extraReducers(builder) {
    builder.addCase(checkCurrentCustomerCanPayOnAccount.fulfilled, (state, action) => {
      if (!action.payload) {
        return { ...state };
      }
      const { canPayOnAccount, companyId, companyName } = action.payload;
      let chargedCompany: Company | undefined;

      if (companyId && companyName) {
        chargedCompany = {
          id: companyId,
          name: companyName,
        };
      }

      return {
        ...state,
        customerCanPayOnAccount: canPayOnAccount,
        chargedCompany,
      };
    });
    builder.addCase(checkCurrentCustomerCanPayOnAccount.rejected, (state) => {
      return {
        ...state,
        customerCanPayOnAccount: false,
      };
    });

    builder.addCase(checkSpecifiedCustomerCanPayOnAccount.fulfilled, (state, action) => {
      if (!action.payload) {
        return { ...state };
      }
      const { canPayOnAccount, companyId, companyName } = action.payload;
      let chargedCompany: Company | undefined;

      if (companyId && companyName) {
        chargedCompany = {
          id: companyId,
          name: companyName,
        };
      }

      return {
        ...state,
        customerCanPayOnAccount: canPayOnAccount,
        chargedCompany,
      };
    });
    builder.addCase(checkSpecifiedCustomerCanPayOnAccount.rejected, (state) => {
      return {
        ...state,
        customerCanPayOnAccount: false,
      };
    });
    builder.addCase(getCustomerAddresses.pending, (state) => ({
      ...state,
      addressesLoading: true,
    }));
    builder.addCase(getCustomerAddresses.fulfilled, (state, action) => ({
      ...state,
      addressesLoading: false,
      customerAddresses: action.payload,
    }));
    builder.addCase(getCustomerAddresses.rejected, (state) => ({
      ...state,
      addressesLoading: false,
    }));
    builder.addCase(getCustomerAddressesByPhone.pending, (state) => ({
      ...state,
      addressesLoading: true,
    }));
    builder.addCase(getCustomerAddressesByPhone.fulfilled, (state, action) => ({
      ...state,
      addressesLoading: false,
      customerAddresses: action.payload.map((address) => {
        return { ...address, isSelected: false }; // for phone number we do not select any of available
      }),
    }));
    builder.addCase(getCustomerAddressesByPhone.rejected, (state) => ({
      ...state,
      addressesLoading: false,
    }));
    builder.addCase(getCustomerSearchResults.pending, (state) => ({
      ...state,
      loadingCustomerSearchResults: true,
      noMoreResults: false,
    }));
    builder.addCase(getCustomerSearchResults.fulfilled, (state, action) => ({
      ...state,
      loadingCustomerSearchResults: false,
      customerSearchResults: action.payload.results,
      noMoreResults: action.payload.noMoreResults,
    }));
    builder.addCase(getCustomerSearchResults.rejected, (state) => ({
      ...state,
      loadingCustomerSearchResults: false,
    }));
    builder.addCase(getCustomerCreditDetails.pending, (state) => ({
      ...state,
      loadingCustomerSearchResults: true,
    }));
    builder.addCase(getCustomerCreditDetails.rejected, (state) => ({
      ...state,
      loadingCustomerSearchResults: false,
    }));
    builder.addCase(getCustomerCreditDetails.fulfilled, (state, action) => ({
      ...state,
      loadingCustomerSearchResults: false,
      customerCreditDetails: action.payload,
    }));
  },
});

const { reducer } = customerSlice;
export const {
  clearSearchResults,
  setCustomerCanPayOnAccount,
  setCustomerAddresses,
  clearCustomerData,
  restartCustomerState,
  setUseCustomerCredits,
  clearCustomerCreditDetails,
  clearCustomerCreditUsage,
  setSelectedCustomerAddress,
  clearSelectedCustomerAddress,
} = customerSlice.actions;

export default reducer;
