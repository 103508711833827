// import { JsonHubProtocol, HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  JsonHubProtocol,
  LogLevel,
} from '@microsoft/signalr';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI, ThunkDispatch } from '@reduxjs/toolkit';
import { API_PREFIX } from 'API/PosApi';
import { RootState } from 'stores';
import { handleEftStatusNotification } from 'stores/Eft';
import {
  EftStatusChangeMessage,
  EtaConfirmationNotifierRefreshMessage,
  EtaConfiguration,
  OrderingOption,
  StoreEtaUpdateRefreshMessage,
  StoreOrderingOptionRefreshMessage,
  NotificationMessage,
} from 'typings/Notifications';
import { updateLastDineInRefreshMessage } from '.';
import {
  disconnectNotifications,
  setNotificationsConnectionId,
  updateEtaConfiguration,
  updateOrderingOption,
} from './notifications.slice';
import { getNoOfOrdersToConfirmEta } from './notifications.thunk-actions';

const startSignalRConnection = (
  connection: HubConnection,
  apiDispatcher: MiddlewareAPI<ThunkDispatch<any, any, any>, any>,
  storeId: number,
  touchpointId: string,
) =>
  connection
    .start()
    .then(() => {
      if (connection.connectionId) {
        apiDispatcher.dispatch(setNotificationsConnectionId(connection.connectionId));
      }

      connection.on('ReceiveDineInRefreshMessage', (message: NotificationMessage) => {
        if (storeId === message.storeId && touchpointId !== message.touchpointId) {
          apiDispatcher.dispatch(
            updateLastDineInRefreshMessage({
              storeId: message.storeId,
              touchpointId: message.touchpointId,
              tabId: message.body.tabId,
            }),
          );
        }
      });

      connection.on('ReceiveEftStatusMessage', (message: EftStatusChangeMessage) => {
        const state = apiDispatcher.getState() as RootState;
        const transactionId = state.eft.eftPaymentId;

        if (transactionId && transactionId === message.transactionId && storeId === message.storeId) {
          apiDispatcher.dispatch(handleEftStatusNotification(message));
        }
      });

      connection.on(
        'ReceiveEtaConfirmationNotifierRefreshMessage',
        (message: EtaConfirmationNotifierRefreshMessage) => {
          if (message.storeId !== storeId) {
            return;
          }
          apiDispatcher.dispatch(getNoOfOrdersToConfirmEta());
        },
      );

      connection.on('ReceiveStoreEtaUpdateRefreshMessage', (message: StoreEtaUpdateRefreshMessage) => {
        if (message.storeId !== storeId) {
          return;
        }

        const etaConfiguration: EtaConfiguration = {
          pickUpEtaMinutes: message.pickUpEtaMinutes,
          deliveryEtaMinutes: message.deliveryEtaMinutes,
        };
        apiDispatcher.dispatch(updateEtaConfiguration(etaConfiguration));
      });

      connection.on('ReceiveStoreOrderingOptionRefreshMessage', (message: StoreOrderingOptionRefreshMessage) => {
        if (message.storeId !== storeId) {
          return;
        }

        const orderingOption: OrderingOption = {
          pickupOrdersAllowed: message.pickupOrdersAllowed,
          deliveryOrdersAllowed: message.deliveryOrdersAllowed,
        };
        apiDispatcher.dispatch(updateOrderingOption(orderingOption));
      });

      connection.on('Disconnect', () => {
        connection
          .stop()
          .then(() => {
            apiDispatcher.dispatch(disconnectNotifications());
          })
          .catch((reason) => {
            console.error(`SingalR disconnection error: ${reason}`);
          });
      });
    })
    .catch((e) => console.error('Connection failed: ', e));

const connectToSignalR = (api: MiddlewareAPI<Dispatch<AnyAction>, any>, storeId: number, touchpointId: string) => {
  const connectionHub = `${API_PREFIX}/hubs/notifications`;
  const protocol = new JsonHubProtocol();
  const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;
  const options = {
    transport,
    logMessageContent: true,
    logger: LogLevel.Error,
    withCredentials: false,
  };
  // create the connection instance
  const connection = new HubConnectionBuilder()
    .withUrl(connectionHub, options)
    .withAutomaticReconnect()
    .withHubProtocol(protocol)
    .build();

  startSignalRConnection(connection, api, storeId, touchpointId);
};

const SignalRMiddleware: Middleware<ThunkDispatch<any, any, any>> = (api) => (next) => (action) => {
  if (
    // Store is selected
    action.type === `[STORE]/getSelectedStore/fulfilled` ||
    // Page is refreshed
    // this never executes correctly, as when this is triggered store and touchpoint id is not yet in app state
    // not sure what to do with it. The other condition seems to cover it, so potentially this can be removed,
    // or dispatched in a way that we already have what we need in the state
    // We should re-visit it when implementing "select existing touchpoint" feature
    action.type === '[NOTIFY]/reconnectToNotifications'
  ) {
    const combinedState = api.getState() as RootState;
    const storeId = combinedState.stores.selectedStore?.id;
    const touchpointId = combinedState.authorization.onsiteMachine?.touchpointId;

    if (combinedState.config.useNotifications && storeId && touchpointId) {
      connectToSignalR(api, storeId, touchpointId);
    }
  }

  return next(action);
};

export default SignalRMiddleware;
