import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  cancelOnPaymentAbandon,
  cancelOrder,
  editOrder,
  emailReceiptToCustomer,
  emailReceiptToStore,
  getAllOrders,
  printOrder,
} from 'stores/AllOrders';
import {
  postFinishTakeAwayOrder,
  postFinishDeliveryOrder,
  postFinishPickupOrder,
  getOrderHistory,
  saveDeliveryOrderEdition,
  savePickupOrderEdition,
  postPlaceUnpaidTakeAwayOrder,
  putAcceptTakeAwayPayment,
  getLocalizationDetails,
  finalizeOrder,
  finalizeOsmOrder,
  getDeliveryAreaDetails,
  finalizeOsmPickupV2,
  fiscalizeOsmOrder,
} from 'stores/Intake';
import {
  postNewTab,
  updateDineInOrder,
  cancelDineInOrder,
  postFinalizeDineInOrder,
  submitPendingIteration,
  swapDineInTables,
  getDineInOrder,
} from 'stores/DineIn/dineIn-thunk.actions';
import { getLocalOrdersList, postReprintOrder } from 'stores/LocalOrders';
import { getPaymentMethods, getTerminalStatus } from 'stores/Payments/payments.thunk-actions';
import { getCustomizedItemPrice } from 'stores/Products';
import { getPrintingConfiguration, getPrintersList } from 'stores/Setup/setup.thunk-actions';
import { AlertEntry, AlertPersistent, AlertsState } from 'typings/Alerts';
import { PosApiErrorResponse } from 'typings/shared-types';
import {
  abandonEftPayment,
  acceptEftPayment,
  cancelEftPayment,
  confirmEftManually,
  retryEftPayment,
} from 'stores/EftPayment/eftPayment.actions';
import {
  acceptPaymentForOrder,
  finalizeOrderAndExit,
  openOrderPayment,
} from 'stores/OrderPayment/orderPayment.actions';
import { getOrdersFiscalStatus } from 'stores/Notifications/notifications.thunk-actions';

export const alertsInitialState: AlertsState = {
  alert: null,
  persistentAlert: null,
  modal: null,
};
const contactSupportMessage = 'Please contact support';

const alertsSlice = createSlice({
  name: '[ALERTS]',
  initialState: alertsInitialState,
  reducers: {
    setAlert(state, action: PayloadAction<AlertEntry | undefined>) {
      if (action.payload) {
        return {
          ...state,
          alert: action.payload,
        };
      }
      return {
        ...state,
        alert: {
          header: 'Something went wrong',
          variant: 'error',
        },
      };
    },
    setPersistentAlert: (state, action: PayloadAction<AlertPersistent>) => ({
      ...state,
      persistentAlert: action.payload,
    }),
    clearAlert: (state) => ({
      ...state,
      alert: null,
    }),
    clearModal: (state) => ({
      ...state,
      modal: null,
    }),
  },
  extraReducers(builder) {
    builder.addCase(getLocalOrdersList.rejected, (state) => ({
      ...state,
      alert: {
        header: 'Loading local orders failed',
        variant: 'error',
      },
    }));
    builder.addCase(getAllOrders.rejected, (state) => ({
      ...state,
      alert: {
        header: 'Loading osm orders failed',
        variant: 'error',
      },
    }));
    builder.addCase(getPaymentMethods.rejected, (state) => ({
      ...state,
      alert: {
        header: 'Loading payment methods failed',
        variant: 'error',
      },
    }));
    builder.addCase(postReprintOrder.rejected, (state) => ({
      ...state,
      alert: {
        header: 'Printing failed',
        variant: 'warning',
      },
    }));
    builder.addCase(saveDeliveryOrderEdition.fulfilled, (state) => ({
      ...state,
      alert: {
        header: 'Saving order details successful',
        message: '',
        position: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      },
    }));
    builder.addCase(saveDeliveryOrderEdition.rejected, (state) => ({
      ...state,
      alert: {
        header: 'Saving order details failed',
        message: 'Something went wrong',
        variant: 'error',
      },
    }));
    builder.addCase(savePickupOrderEdition.fulfilled, (state) => ({
      ...state,
      alert: {
        header: 'Saving order details successful',
        message: '',
        position: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      },
    }));
    builder.addCase(emailReceiptToCustomer.fulfilled, (state) => ({
      ...state,
      alert: {
        header: 'Order receipt sent to customer successfully',
        message: '',
        position: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      },
    }));
    builder.addCase(emailReceiptToCustomer.rejected, (state) => ({
      ...state,
      alert: {
        header: 'Sending order receipt to customer failed',
        message: '',
        variant: 'error',
      },
    }));
    builder.addCase(emailReceiptToStore.fulfilled, (state) => ({
      ...state,
      alert: {
        header: 'Order receipt sent to store successfully',
        message: '',
        position: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      },
    }));
    builder.addCase(emailReceiptToStore.rejected, (state) => ({
      ...state,
      alert: {
        header: 'Sending order receipt to store failed',
        message: '',
        variant: 'error',
      },
    }));
    builder.addCase(savePickupOrderEdition.rejected, (state) => ({
      ...state,
      alert: {
        header: 'Saving order details failed',
        message: 'Something went wrong',
        variant: 'error',
      },
    }));
    builder.addCase(getOrderHistory.fulfilled, (state, action) => ({
      ...state,
      alert: {
        header: action.payload.isUnpaid ? 'Unpaid order placed. Waiting for payment' : 'Order placed successfully',
        message: 'Ticket nr',
        messageAddon: action.payload?.ticketNumber,
        secondLine: action.payload?.changeAmount ? 'Change' : undefined,
        secondLineAddon: action.payload?.changeAmount,
        position: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      },
    }));
    builder.addCase(putAcceptTakeAwayPayment.fulfilled, (state, { payload }) => ({
      ...state,
      alert: {
        header: 'Order finalized successfully',
        message: payload?.ticketNumber && 'Ticket nr',
        messageAddon: payload?.ticketNumber,
        secondLine: payload?.changeAmount ? 'Change' : undefined,
        secondLineAddon: payload?.changeAmount,
        position: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      },
    }));
    builder.addCase(putAcceptTakeAwayPayment.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Accepting payment for unpaid order failed',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(finalizeOrder.fulfilled, (state, { payload }) => ({
      ...state,
      alert: {
        header: 'Order finalized successfully',
        message: payload?.ticketNumber && 'Ticket nr',
        messageAddon: payload?.ticketNumber,
        secondLine: payload?.changeAmount ? 'Change' : undefined,
        secondLineAddon: payload?.changeAmount,
        position: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      },
    }));
    builder.addCase(finalizeOsmOrder.fulfilled, (state, { payload }) => ({
      ...state,
      alert: {
        header: 'Order finalized successfully',
        message: payload?.ticketNumber && 'Ticket nr',
        messageAddon: payload?.ticketNumber,
        secondLine: payload?.changeAmount ? 'Change' : undefined,
        secondLineAddon: payload?.changeAmount,
        position: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      },
    }));
    builder.addCase(finalizeOsmOrder.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Order finalization failed',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(fiscalizeOsmOrder.rejected, (state) => {
      return {
        ...state,
        modal: {
          header: 'Could not fiscalize order',
          message: contactSupportMessage,
          position: {
            horizontal: 'right',
            vertical: 'bottom',
          },
        },
      };
    });
    builder.addCase(fiscalizeOsmOrder.fulfilled, (state) => ({
      ...state,
      alert: {
        header: 'Order fiscalized',
        position: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      },
    }));
    builder.addCase(finalizeOrder.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Order finalization failed',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(postPlaceUnpaidTakeAwayOrder.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Placing order failed',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(postFinishTakeAwayOrder.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Placing order failed',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(postFinishDeliveryOrder.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Placing order failed',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(postFinishPickupOrder.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Placing order failed',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(postReprintOrder.fulfilled, (state) => ({
      ...state,
      alert: {
        header: 'Printing started',
      },
    }));
    builder.addCase(getPrintingConfiguration.rejected, (state) => ({
      ...state,
      alert: {
        header: 'Error getting printer configuration',
        variant: 'error',
      },
    }));
    builder.addCase(getPrintersList.rejected, (state) => ({
      ...state,
      alert: {
        header: 'Error getting printers',
        variant: 'error',
      },
    }));
    builder.addCase(getLocalizationDetails.rejected, (state) => ({
      ...state,
      alert: {
        header: 'Error getting localization details',
        variant: 'error',
      },
    }));
    builder.addCase(getTerminalStatus.rejected, (state) => ({
      ...state,
      alert: {
        header: 'Error getting terminal status',
        variant: 'error',
      },
    }));
    builder.addCase(printOrder.rejected, (state) => ({
      ...state,
      alert: {
        header: 'Error printing order',
        variant: 'error',
      },
    }));
    builder.addCase(editOrder.fulfilled, (state) => ({
      ...state,
      alert: {
        header: 'Order edited successfully',
        position: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      },
    }));
    builder.addCase(editOrder.rejected, (state) => ({
      ...state,
      alert: {
        header: 'Error editing order',
        variant: 'error',
      },
    }));
    builder.addCase(getCustomizedItemPrice.rejected, (state, action) => ({
      ...state,
      modal: {
        header: 'Error calculating product',
        message: (action.payload as PosApiErrorResponse)?.errorCode,
      },
    }));
    builder.addCase(postNewTab.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Error opening tab',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(getDineInOrder.rejected, (state, action) => ({
      ...state,
      modal: {
        header: 'Error retrieving tab',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(updateDineInOrder.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Error updating tab',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(postFinalizeDineInOrder.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Error finalizing tab',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(submitPendingIteration.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Error opening or updating tab',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(cancelDineInOrder.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Error cancelling tab',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(swapDineInTables.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Error swapping tables',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(getDeliveryAreaDetails.rejected, (state) => {
      return {
        ...state,
        alert: {
          header: 'Cannot load delivery areas information',
          variant: 'warning',
        },
      };
    });
    builder.addCase(acceptEftPayment.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Error accepting payment',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(retryEftPayment.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Error retrying eft payment',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(cancelEftPayment.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Error canceling payment',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(abandonEftPayment.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Error abandoning payment',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(acceptPaymentForOrder.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Error accepting payment for order',
        message: contactSupportMessage,
      },
    }));
    builder.addCase(confirmEftManually.rejected, (state) => ({
      ...state,
      modal: {
        header: 'Error on manual confirmation of eft payment',
        message: contactSupportMessage,
      },
    }));

    builder.addCase(acceptEftPayment.fulfilled, (state) => ({
      ...state,
      alert: {
        header: 'Order payment successfully processed',
        variant: 'success',
      },
    }));
    builder.addCase(acceptPaymentForOrder.fulfilled, (state) => ({
      ...state,
      alert: {
        header: 'Order payment successfully processed',
        variant: 'success',
      },
    }));
    builder.addCase(cancelOnPaymentAbandon.fulfilled, (state, action) => ({
      ...state,
      alert: {
        header: 'Unpaid order had been canceled',
        message: action.payload.length > 0 ? 'Ticket nr' : undefined,
        messageAddon: action.payload.length > 0 ? action.payload : undefined,
        position: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      },
    }));
    builder.addCase(cancelOnPaymentAbandon.rejected, (state) => ({
      ...state,
      alert: {
        header: 'Unpaid order cancellation failed',
        variant: 'error',
      },
    }));
    builder.addCase(openOrderPayment.rejected, (state) => {
      return {
        ...state,
        modal: {
          header: 'Could not open payment for order',
          message: contactSupportMessage,
        },
      };
    });
    builder.addCase(finalizeOrderAndExit.rejected, (state) => {
      return {
        ...state,
        modal: {
          header: 'Could not finalize order payment',
          message: contactSupportMessage,
        },
      };
    });
    builder.addCase(finalizeOrderAndExit.fulfilled, (state, action) => {
      return {
        ...state,
        alert: {
          header: 'Order paid and finalized',
          message: action.payload.length > 0 ? 'Ticket nr' : undefined,
          messageAddon: action.payload.length > 0 ? action.payload : undefined,
          position: {
            horizontal: 'right',
            vertical: 'bottom',
          },
        },
      };
    });
    builder.addCase(finalizeOsmPickupV2.rejected, (state) => {
      return {
        ...state,
        modal: {
          header: 'Could not close pickup order',
          message: contactSupportMessage,
        },
      };
    });
    builder.addCase(cancelOrder.rejected, (state, action) => {
      return {
        ...state,
        modal: {
          header: 'Issue canceling order',
          message: action.payload as string,
        },
      };
    });
    builder.addCase(getOrdersFiscalStatus.fulfilled, (state, action) => {
      const ordersCount = action.payload?.notFiscalizedOrders?.length ?? 0;
      const message = `Orders with fiscal error:`;
      return {
        ...state,
        persistentAlert:
          ordersCount > 0
            ? {
                message,
                messageAddon: ` ${ordersCount}`,
                variant: 'error',
              }
            : null,
      };
    });
  },
});

const { reducer } = alertsSlice;
export const { setAlert, clearAlert, clearModal } = alertsSlice.actions;

export default reducer;
