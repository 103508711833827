import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, sizing, radius, spacing }) => {
  return {
    toppingCard: {
      height: sizing.productSetToppingCardHeight,
      position: 'relative',
      backgroundColor: palette.white,
      border: `1px solid ${palette.customGray.main}`,
      // hack necessary to maintain item placement after activating card
      margin: '1px',
      borderRadius: radius.sm,
    },
    activeCard: {
      borderColor: palette.black.main,
      borderWidth: '2px',
      margin: spacing(0),
    },
    button: {
      backgroundColor: palette.customGray.main,
      width: sizing.buttonBox.xs,
      height: sizing.buttonBox.xs,
      padding: 0,
      minWidth: 'unset',
      color: palette.black.main,
    },
    removeButton: {
      color: palette.red[600],
      backgroundColor: palette.red[200],
    },
  };
});

export default buildClasses;
