import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import { Product } from 'typings/Products';

export const getToppingsState = ({ toppings }: RootState) => toppings;
export const getAllToppings = ({ toppings }: RootState) => toppings.toppings;

export const getAvailableToppingsForProduct = (product: Product) =>
  createSelector([getAllToppings], (allToppings) => {
    return allToppings.filter((el) => product.forbiddenToppings.some((ft) => ft.toppingId === el.id));
  });
