import React, { ReactNode } from 'react';
import { Dialog, DialogTitle, AppBar, IconButton, Button, useTheme, Box, SxProps, Theme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import buildClasses from './ModalContainer.css';

interface ModalContainerProps {
  onClose: () => void;
  title: string;
  children: ReactNode;
  open: boolean;
  maxWidth?: 'sm' | 'md' | 'lg';
  primaryButton: {
    label: string;
    testId: string;
    action?: () => void;
    form?: string;
    type?: 'button' | 'reset' | 'submit';
    disabled?: boolean;
  };
  secondaryButton?: {
    label: string;
    testId: string;
    action: () => void;
  };
  testId: string;
  showChildrenOnly?: boolean;
  primaryButtonOverride?: JSX.Element;
  allowCloseOnlyByButton?: boolean;
  hideCloseIcon?: boolean;
  sx?: SxProps<Theme>;
}

const ModalContainer: React.FC<ModalContainerProps> = ({
  onClose,
  title,
  children,
  open,
  primaryButton,
  secondaryButton,
  testId,
  showChildrenOnly,
  primaryButtonOverride,
  allowCloseOnlyByButton,
  maxWidth,
  hideCloseIcon,
  sx,
}) => {
  const { classes } = buildClasses();
  const { spacing, palette } = useTheme();

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paperWidthLg': {
          minWidth: '40vw',
        },
      }}
      maxWidth={maxWidth ?? 'lg'}
      onClose={(_, reason) => {
        if (!allowCloseOnlyByButton || reason !== 'backdropClick') {
          onClose();
        }
      }}
      aria-labelledby="simple-dialog-title"
      open={open}
      data-testid={testId}
      disableEscapeKeyDown={allowCloseOnlyByButton}
      PaperProps={{
        sx: { ...sx },
      }}
    >
      <AppBar position="relative" sx={{ boxShadow: 'none' }}>
        <DialogTitle id="simple-dialog-title" sx={{ padding: `${spacing(2.5)} ${spacing(3)}` }}>
          {title}
        </DialogTitle>
        {!hideCloseIcon && (
          <IconButton
            onClick={onClose}
            disableRipple
            sx={{
              position: 'absolute',
              top: spacing(1),
              right: spacing(1),
              color: palette.text.secondary,
              fontSize: spacing(2.5),
            }}
            color="inherit"
            size="medium"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}
      </AppBar>
      {children}
      {!showChildrenOnly && (
        <footer className={classes.footer}>
          {secondaryButton && (
            <Button
              color="black"
              variant="outlined"
              sx={{ fontSize: spacing(2) }}
              onClick={secondaryButton.action}
              data-testid={secondaryButton.testId}
            >
              {secondaryButton.label}
            </Button>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 'auto' }}>
            {primaryButtonOverride || (
              <Button
                sx={{ fontSize: '1rem' }}
                type={primaryButton.type}
                form={primaryButton.form}
                data-testid={primaryButton.testId}
                onClick={primaryButton.action}
                color="primary"
                variant="contained"
                disabled={primaryButton.disabled}
              >
                {primaryButton.label}
              </Button>
            )}
          </Box>
        </footer>
      )}
    </Dialog>
  );
};

export default ModalContainer;
