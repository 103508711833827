import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CustomerDisplayState from 'typings/CustomerDisplay';

const initialState: CustomerDisplayState = {
  receiptProducts: [],
  receiptDiscounts: [],
};

const customerDisplaySlice = createSlice({
  name: '[CUSTOMER-DISPLAY]',
  initialState,
  reducers: {
    updateCustomerDisplayBasket(state, action: PayloadAction<CustomerDisplayState>) {
      return {
        ...state,
        receiptProducts: action.payload.receiptProducts,
        receiptDiscounts: action.payload.receiptDiscounts,
        basketSummary: action.payload.basketSummary,
      };
    },
    showIntakeFinalizeSuccessMessage(state) {
      return {
        ...state,
        showIntakeFinalizedMessage: true,
      };
    },
    hideIntakeFinalizeSuccessMessage(state) {
      return {
        ...state,
        showIntakeFinalizedMessage: undefined,
      };
    },
    setCashierIsAway(state, action: PayloadAction<boolean | undefined>) {
      return {
        ...state,
        cashierIsAway: action.payload,
      };
    },
    clearCustomerDisplayBasket(state) {
      return {
        ...state,
        basketSummary: undefined,
        cashierIsAway: undefined,
        receiptDiscounts: [],
        receiptProducts: [],
      };
    },
  },
});

const { reducer } = customerDisplaySlice;

export const {
  updateCustomerDisplayBasket,
  showIntakeFinalizeSuccessMessage,
  hideIntakeFinalizeSuccessMessage,
  clearCustomerDisplayBasket,
  setCashierIsAway,
} = customerDisplaySlice.actions;

export default reducer;
