import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { setAlert } from 'stores/Alerts';
import { ConfigurationElement } from 'typings/Config';
import { isValidAxiosResponse } from 'typings/type-guards';
import {
  AppVersionInformation,
  ConfigResponse,
  FeatureFlags,
  SyncHistoryRecord,
  SyncStatus,
  UpgradeStatus,
} from './configTypes';

export const loadAllConfig = createAsyncThunk('[CONFIG]/loadPosConfig', async () => {
  const result = await posApi.get<ConfigResponse>(posApiUrls.POS_UI_CONFIG);

  if (!isValidAxiosResponse(result)) {
    setAlert({ header: 'Loading configuration failed', variant: 'error' });
  }

  const sortedAddressInput =
    result.data?.addressInput?.configurationElements &&
    [...result.data.addressInput.configurationElements].sort((a, b) => a.line - b.line);

  return {
    ...result.data,
    addressInput: { configurationElements: sortedAddressInput },
  };
});

export const loadFeatureFlags = createAsyncThunk('[CONFIG]/loadFeatureFlags', async () => {
  const result = await posApi.get<FeatureFlags>(posApiUrls.CONFIGURATION_FEATURE_FLAGS);

  if (!isValidAxiosResponse(result)) {
    setAlert({ header: 'Loading configuration failed', variant: 'error' });
  }
  return result.data;
});

export const getFirstTimeLaunchInfo = createAsyncThunk('[CONFIG]/getFirstTimeLaunchInfo', async () => {
  const result = await posApi.get<boolean>(posApiUrls.INITIAL_SETUP_SHOW_FIRST_TIME_LAUNCH);
  return result.data;
});

export const getAppVersion = createAsyncThunk('[CONFIG]/getAppVersion', async () => {
  const result = await posApi.get<string>(posApiUrls.DEV_APP_VERSION);

  return mapToAppVersionObject(result.data);
});

export function mapToAppVersionObject(response: string): AppVersionInformation {
  const separatorPosition = response.indexOf('+');
  if (separatorPosition !== -1) {
    return {
      baseVersion: response.slice(0, separatorPosition),
      gitSha: response.slice(separatorPosition + 1, response.length),
    };
  }
  return {
    baseVersion: response,
  };
}

export const getVersionUpgradeStatus = createAsyncThunk('[CONFIG]/getVersionUpgradeStatus', async () => {
  const result = await posApi.get<UpgradeStatus>(posApiUrls.VERSION_UPGRADE_STATUS);

  return result.data;
});

export const triggerVersionUpgradeRetry = createAsyncThunk('[CONFIG]/getVersionUpgradeRetry', async () => {
  const result = await posApi.put(posApiUrls.VERSION_UPGRADE_RETRY);

  return result.data;
});

export const getSyncStatus = createAsyncThunk('[CONFIG]/getSyncStatus', async () => {
  const result = await posApi.get<SyncStatus>(posApiUrls.SYNCHRONIZATION_STATUS);

  return result.data;
});

export const runSynchronization = createAsyncThunk('[CONFIG]/runSynchronization', async (_, { dispatch }) => {
  const result = await posApi.post<void>(posApiUrls.SYNCHRONIZATION_RUN);

  dispatch(getSyncHistory());

  return result;
});

export const getSyncHistory = createAsyncThunk('[CONFIG]/getSyncHistory', async () => {
  const result = await posApi.get<SyncHistoryRecord[]>(posApiUrls.SYNCHRONIZATION_HISTORY, {
    params: {
      PageNumber: 1,
      PageSize: 5,
    },
  });

  return result.data;
});
