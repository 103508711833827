/* eslint-disable @typescript-eslint/no-explicit-any */
import { HybridModeCommunicator } from 'typings/HybridMode';

class Communicator implements HybridModeCommunicator {
  communicationHub: any;

  constructor() {
    const wrapperWindow = window as any;

    if (wrapperWindow.hybridCommunicationHub) {
      this.communicationHub = wrapperWindow.hybridCommunicationHub;
    }
  }

  send(messageName: string, body?: object): void {
    if (!isHybridModeCommunicationAvailable()) {
      return;
    }

    let payload: string | undefined;

    if (body) {
      payload = JSON.stringify(body);
    }

    this.communicationHub.send(messageName, payload);
  }

  subscribe(messageName: string, callback: (payload: string) => void): void {
    if (!isHybridModeCommunicationAvailable()) {
      return;
    }

    this.communicationHub.receive(messageName, (messageData: string) => {
      callback(messageData);
    });
  }
}

export function getHybridModeCommunicator(): HybridModeCommunicator {
  return new Communicator();
}

export function isHybridModeCommunicationAvailable(): boolean {
  const wrapperWindow = window as any;

  return wrapperWindow.hybridCommunicationHub !== undefined;
}
