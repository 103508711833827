import React from 'react';
import Switch, { SwitchProps } from '@mui/material/Switch';
import buildClasses from './FilledSwitch.css';

const FilledSwitch: React.FC<SwitchProps> = ({ name, inputRef, checked, onChange }) => {
  const { classes } = buildClasses();

  return (
    <Switch
      onChange={onChange}
      checked={checked}
      name={name as string}
      classes={{
        root: classes?.root,
        switchBase: classes?.switchBase,
        thumb: classes?.thumb,
        track: classes?.track,
        checked: classes?.checked,
      }}
      disableRipple
      inputRef={inputRef}
    />
  );
};

export default FilledSwitch;
