import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { Topping, ToppingCategory } from 'typings/Products';

export const fetchAllToppings = createAsyncThunk('[TOPPINGS]/fetchAllToppings', async () => {
  const result = await posApi.get<Topping[]>(posApiUrls.TOPPINGS_GET);
  return result.data;
});
export const fetchToppingCategories = createAsyncThunk('[TOPPINGS]/fetchToppingCategories', async () => {
  const result = await posApi.get<ToppingCategory[]>(posApiUrls.TOPPINGS_CATEGORIES);
  return result.data;
});
