import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing, sizing, transitions }) => {
  return {
    buttonBox: {
      color: palette.grey[800],
      whiteSpace: 'nowrap',
      padding: `${spacing(1)} ${spacing(2)}`,
      width: sizing.full,
      display: 'flex',
      flexDirection: 'column',
      transition: transitions.create(['background-color', 'transform'], {
        duration: transitions.duration.standard,
      }),
      '&:hover': {
        color: palette.black.main,
        fontWeight: 'bold',
        borderColor: palette.primary.main,
      },
      borderBottom: `${spacing(0.5)} solid ${palette.white}`,
      borderColor: 'transparent',
    },
    active: {
      color: palette.black.main,
      fontWeight: 'bold',
      borderColor: palette.primary.main,
    },
  };
});

export default buildClasses;
