import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { getAppInsights } from 'App/AppInitializationWrapper/AppInitializationWrapper';
import { AxiosError } from 'axios';
import { RootState } from 'stores';
import { EftStatusChangeMessage } from 'typings/Notifications';
import {
  RequestEftPaymentResult,
  GetEftPaymentStatusResult,
  EftPaymentTerminal,
  ExecutionResultStatus,
  EftError,
  PaymentTarget,
} from 'typings/Payments';
import { isValidAxiosResponse } from 'typings/type-guards';

export const startEftPayment = createAsyncThunk<
  string,
  { amount: number; identifier: string; orderId: string; paymentTarget: PaymentTarget; giftCardNumber?: number },
  { state: RootState; rejectValue?: string }
>(
  '[EFT]/startEftPayment',
  async ({ amount, identifier, orderId, paymentTarget }, { getState, rejectWithValue }) => {
    const { selectedStore } = getState().stores;

    if (!selectedStore) {
      return rejectWithValue('Cannot start EFT without store selected');
    }

    const result = await posApi
      .put<RequestEftPaymentResult>(posApiUrls.EFT_PAYMENT(identifier, amount, orderId, paymentTarget))
      .catch((err: AxiosError<{ title: string }>) => err);

    if (isValidAxiosResponse(result)) {
      if (result.data.success !== true) {
        return rejectWithValue(result.data.errorCode);
      }
      return identifier;
    }

    return rejectWithValue(result?.response?.data?.title ?? result?.response?.data);
  },
);

export const getEftPaymentStatus = createAsyncThunk<
  GetEftPaymentStatusResult,
  { identifier: string; orderId: string; paymentTarget: PaymentTarget }
>('[EFT]/getEftPaymentStatus', async ({ identifier, orderId, paymentTarget }, { rejectWithValue }) => {
  const response = await posApi.get<GetEftPaymentStatusResult>(
    posApiUrls.EFT_PAYMENT_STATUS(identifier, orderId, paymentTarget),
  );

  if (response.status === 200) {
    const appInsights = getAppInsights();
    appInsights.trackEvent({
      name: 'Eft payment status update',
      properties: { Identifier: identifier, Status: response.data.status },
    });
    return response.data;
  }
  return rejectWithValue(
    response instanceof AxiosError ? response.response?.data.title : EftError.EftResultUnknown,
  );
});

export const getEftPaymentTerminal = createAsyncThunk<
  EftPaymentTerminal | undefined,
  { checkCtmp: boolean | undefined },
  { state: RootState }
>('[EFT]/getEftPaymentTerminal', async ({ checkCtmp }) => {
  const response = await posApi.get<EftPaymentTerminal>(posApiUrls.EFT_PAYMENT_TERMINAL(checkCtmp ?? false));

  if (response.status === 200) {
    return response.data;
  }

  return undefined;
});

export const handleEftStatusNotification = createAsyncThunk<boolean, EftStatusChangeMessage, { state: RootState }>(
  '[EFT]/handleEftStatusNotification',
  async (eftChangeNotificationMessage, { rejectWithValue, getState }) => {
    const { eftPaymentId } = getState().eft;
    const appInsights = getAppInsights();
    appInsights.trackEvent({
      name: 'SignalR eft payment status updated',
      properties: { Identifier: eftPaymentId, Message: eftChangeNotificationMessage },
    });
    if (
      eftChangeNotificationMessage.status === ExecutionResultStatus.Succeeded &&
      eftPaymentId === eftChangeNotificationMessage.transactionId
    ) {
      return true;
    }
    return rejectWithValue(eftChangeNotificationMessage.responseMessage);
  },
);

export const cancelEftPayment = createAsyncThunk<
  boolean,
  { orderId: string; eftPaymentId?: string; paymentTarget: PaymentTarget },
  { state: RootState }
>('[EFT]/cancelEftPayment', async ({ orderId, eftPaymentId, paymentTarget }, { getState }) => {
  const { selectedStore } = getState().stores;

  if (!selectedStore) {
    return false;
  }
  const appInsights = getAppInsights();
  appInsights.trackEvent({
    name: 'Eft payment cancelation requested',
    properties: { Identifier: eftPaymentId },
  });
  const response = await posApi.post(posApiUrls.EFT_CANCEL_PAYMENT(orderId, paymentTarget, eftPaymentId));

  if (response.status === 200 || response.status === 204) {
    return true;
  }

  return false;
});
