import crypto from 'crypto';

function base64URLEncode(str: Buffer): string {
  return str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}
function sha256(buffer: string): Buffer {
  return crypto.createHash('sha256').update(buffer).digest();
}

export default function generateCodeChallenge(): { codeVerifier: string; codeChallenge: string } {
  const codeVerifier = base64URLEncode(crypto.randomBytes(32));
  const codeChallenge = base64URLEncode(sha256(codeVerifier));
  return {
    codeVerifier,
    codeChallenge,
  };
}
