import { createSlice } from '@reduxjs/toolkit';
import {
  activateGiftCard,
  checkOrderGiftCards,
  checkSpecifiedGiftCard,
  getActivationStep,
} from 'stores/GiftCardActivation/giftCardActivation.actions';
import { OrderPaymentDetails } from 'stores/OrderPayment/orderPayment.slice';
import { GiftCardActivationStatus } from 'typings/Payments/giftCardTypes';

export type ActivationStep = 'enterData' | 'inProgress' | 'success' | 'failure';

export interface GiftCardActivation {
  isActiveStep?: boolean;
  id?: string;
  amount: number;
  status: GiftCardActivationStatus;
  activationStep: ActivationStep;
}

export interface GiftCardActivationState {
  orderId?: string;
  isLocalOrder?: boolean;
  giftCards: GiftCardActivation[];
  errorMessage?: string;
  details?: OrderPaymentDetails;
}

const initialState: GiftCardActivationState = {
  giftCards: [],
};

const giftCardActivationSlice = createSlice({
  name: '[GiftCardActivation]',
  initialState,
  reducers: {
    clearGiftCardActivation: () => ({
      ...initialState,
    }),
    resetCurrentActivation: (state) => ({
      ...state,
      giftCards: state.giftCards.map((gc) => {
        return { ...gc, activationStep: gc.isActiveStep ? 'enterData' : gc.activationStep };
      }),
    }),
  },

  extraReducers: (builder) => {
    builder.addCase(checkOrderGiftCards.fulfilled, (state, action) => {
      const newGiftCards = action.payload.cards.map((gc, index) => {
        return { ...gc, isActiveStep: false, activationStep: getActivationStep(gc) };
      });

      const firstToActivate = newGiftCards.findIndex((el) => el.status !== GiftCardActivationStatus.Succeeded);
      if (firstToActivate > -1) {
        newGiftCards[firstToActivate].isActiveStep = true;
      }
      return {
        ...state,
        details: action.payload.details,
        orderId: action.payload.orderId,
        giftCards: newGiftCards,
      };
    });
    builder.addCase(activateGiftCard.pending, (state) => {
      return {
        ...state,
        errorMessage: undefined,
        giftCards: state.giftCards.map((gc) => {
          return { ...gc, activationStep: gc.isActiveStep ? 'inProgress' : gc.activationStep };
        }),
      };
    });
    builder.addCase(activateGiftCard.fulfilled, (state, action) => {
      const newGiftCards = state.giftCards.map((gc) => {
        return {
          ...gc,
          activationStep: gc.id === action.payload ? 'success' : gc.activationStep,
          isActiveStep: false,
        };
      });

      const currentActiveIndex = state.giftCards.findIndex((el) => el.id === action.payload);

      const leftToActivateIndex = newGiftCards.findIndex((el) => el.activationStep !== 'success');

      if (leftToActivateIndex > 0) {
        newGiftCards[leftToActivateIndex].activationStep = 'enterData';
        newGiftCards[leftToActivateIndex].isActiveStep = true;
      } else {
        newGiftCards[currentActiveIndex].isActiveStep = true;
      }
      return {
        ...state,
        errorMessage: undefined,
        giftCards: newGiftCards,
      };
    });
    builder.addCase(activateGiftCard.rejected, (state, action) => {
      return {
        ...state,
        errorMessage: action.payload as string,
        giftCards: state.giftCards.map((gc) => {
          return { ...gc, activationStep: gc.isActiveStep ? 'failure' : gc.activationStep };
        }),
      };
    });
    builder.addCase(checkSpecifiedGiftCard.fulfilled, (state, action) => {
      return {
        ...state,
        giftCards: state.giftCards.map((gc) => {
          if (gc.id === action.payload.id)
            return {
              ...action.payload,
              activationStep: 'success',
              isActiveStep: false,
            };
          return { ...gc };
        }),
      };
    });
  },
});

const { reducer } = giftCardActivationSlice;

export const { clearGiftCardActivation, resetCurrentActivation } = giftCardActivationSlice.actions;

export default reducer;
