import { PaymentMethod, PaymentMethodCode, PaymentSuccessfulResult } from 'typings/Payments';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  acceptPaymentForOrder,
  calculateChange,
  checkIfOrderIsFullyPaid,
  finalizeOrderAndExit,
  getOrderTicketNumberForPayment,
  openOrderPayment,
} from 'stores/OrderPayment/orderPayment.actions';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';

type PaymentStep = 'provideQrCode' | 'provideAmount' | 'provideTip' | 'confirm' | 'finalizePayment';
export interface OrderPaymentPart {
  paymentId?: string;
  isActive: boolean;
  toPayAmount: number;
  customerPaidWith: number;
  paymentMethod?: PaymentMethod;
  paymentStep: PaymentStep;
  tipValue: number;
  isCompleted: boolean;
}

export interface OrderPaymentDetails {
  ticketNumber: number;
  tableName?: string;
  orderReference?: string;
}

export interface OrderPayment {
  showOrderPayment: boolean;
  orderTotalAmount: number;
  totalPaidAmount: number;
  totalLeftAmount: number;
  isPaid: boolean;
  changeAmount?: number;
  availablePaymentMethods: PaymentMethod[];
  selectedPaymentMethodGroupName?: string;
  payments: OrderPaymentPart[];
  successfulTrigger?: string;
  cancelTrigger?: string;
  abandonTrigger?: string;
  orderId?: string;
  openTabId?: string;
  isLocalOrder?: boolean;
  cancelOnClose?: boolean;
  acceptanceInProgress?: boolean;
  cancelInProgress?: boolean;
  orderPaymentStatusInProgress?: boolean;
  openOrderPaymentInProgress?: boolean;
  orderFinalizationInProgress?: boolean;
  details?: OrderPaymentDetails;
  deliveryType?: PickUpTypesValues;
}

const initialState: OrderPayment = {
  availablePaymentMethods: [],
  showOrderPayment: false,
  isPaid: false,
  orderTotalAmount: 0,
  totalLeftAmount: 0,
  totalPaidAmount: 0,
  payments: [],
};

const orderPaymentSlice = createSlice({
  name: '[OrderPayment]',
  initialState,
  reducers: {
    clearOrderPaymentState: () => ({
      ...initialState,
    }),
    clearActivePayment: (state) => ({
      ...state,
      payments: state.payments.map((pm) => {
        if (pm.isActive) {
          return {
            ...pm,
            paymentMethod: undefined,
            customerPaidWith: 0,
            tipValue: 0,
            paymentStep: 'provideAmount',
          };
        }
        return pm;
      }),
    }),
    setPaymentParts(state, action: PayloadAction<OrderPaymentPart[]>) {
      return {
        ...state,
        payments: action.payload,
      };
    },
    addActivePayment(state, action: PayloadAction<OrderPaymentPart>) {
      return {
        ...state,
        payments: [
          ...state.payments.map((pm) => {
            return { ...pm, isActive: false };
          }),
          { ...action.payload, isActive: true },
        ],
      };
    },
    setActivePayment(state, action: PayloadAction<OrderPaymentPart>) {
      return {
        ...state,
        payments: state.payments.map((pm) => {
          if (pm.isActive) {
            return {
              ...action.payload,
            };
          }
          return pm;
        }),
      };
    },
    setActivePaymentMethod(state, action: PayloadAction<PaymentMethod>) {
      return {
        ...state,
        payments: state.payments.map((pm) => {
          if (pm.isActive) {
            return {
              ...pm,
              paymentMethod: action.payload,
              paymentStep: 'provideAmount',
              tipValue: 0,
              toPayAmount: state.totalLeftAmount,
            };
          }
          return pm;
        }),
      };
    },
    setActivePaymentTip(state, action: PayloadAction<number>) {
      if (action.payload < 0) return state;

      return {
        ...state,
        payments: state.payments.map((pm) => {
          if (pm.isActive) {
            return { ...pm, tipValue: action.payload };
          }
          return pm;
        }),
      };
    },
    setActivePaymentStep(state, action: PayloadAction<PaymentStep>) {
      return {
        ...state,
        payments: state.payments.map((pm) => {
          if (pm.isActive) {
            return { ...pm, paymentStep: action.payload };
          }
          return pm;
        }),
      };
    },
    setActivePaymentCustomerPaysWith(state, action: PayloadAction<number>) {
      return {
        ...state,
        payments: state.payments.map((pm) => {
          if (pm.isActive) {
            return { ...pm, customerPaidWith: action.payload };
          }
          return pm;
        }),
      };
    },
    setSuccessfulTrigger(state, action: PayloadAction<string>) {
      return {
        ...state,
        successfulTrigger: action.payload,
      };
    },
    setCancelTrigger(state, action: PayloadAction<string>) {
      return {
        ...state,
        cancelTrigger: action.payload,
      };
    },
    setAbandonTrigger(state, action: PayloadAction<string>) {
      return {
        ...state,
        abandonTrigger: action.payload,
      };
    },
    setSelectedPaymentMethodGroupName(state, action: PayloadAction<string | undefined>) {
      return {
        ...state,
        selectedPaymentMethodGroupName: action.payload,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(openOrderPayment.pending, (state, action) => {
      return {
        ...state,
        openOrderPaymentInProgress: true,
      };
    });
    builder.addCase(openOrderPayment.fulfilled, (state, action) => {
      return {
        ...state,
        showOrderPayment: true,
        openOrderPaymentInProgress: false,
        orderTotalAmount: action.payload.totalToPay,
        totalLeftAmount: action.payload.leftToPay,
        totalPaidAmount: action.payload.paidAmount,
        isPaid: action.payload.isPaid,
        orderId: action.payload.orderId,
        openTabId: action.payload.openTabId,
        deliveryType: action.payload.deliveryType,
        details: action.payload.orderPaymentDetails,
        availablePaymentMethods: action.payload.availablePaymentMethods ?? [],
        isLocalOrder: action.payload.isLocalOrder,
        cancelOnClose: action.payload.cancelOnClose,
        payments: action.payload.orderPayments,
        selectedPaymentMethodGroupName: action.payload.groupName,
      };
    });
    builder.addCase(openOrderPayment.rejected, (state, action) => {
      return {
        ...state,
        openOrderPaymentInProgress: false,
      };
    });
    builder.addCase(calculateChange.fulfilled, (state, action) => {
      return {
        ...state,
        changeAmount: action.payload,
      };
    });
    builder.addCase(acceptPaymentForOrder.pending, (state) => ({
      ...state,
      acceptanceInProgress: true,
    }));
    builder.addCase(acceptPaymentForOrder.fulfilled, (state) => ({
      ...state,
      acceptanceInProgress: false,
      payments: state.payments.map((pm) => {
        if (pm.isActive) {
          return { ...pm, isCompleted: true };
        }
        return pm;
      }),
    }));
    builder.addCase(acceptPaymentForOrder.rejected, (state) => ({
      ...state,
      acceptanceInProgress: false,
    }));
    builder.addCase(getOrderTicketNumberForPayment.fulfilled, (state, action) => ({
      ...state,
      details: action.payload,
    }));
    builder.addCase(checkIfOrderIsFullyPaid.pending, (state) => ({
      ...state,
      orderPaymentStatusInProgress: true,
    }));
    builder.addCase(checkIfOrderIsFullyPaid.fulfilled, (state, action) => ({
      ...state,
      orderPaymentStatusInProgress: false,
      totalLeftAmount: action.payload.leftToPay,
      totalPaidAmount: action.payload.paidAmount,
      isPaid: action.payload.isPaid,
    }));
    builder.addCase(checkIfOrderIsFullyPaid.rejected, (state) => ({
      ...state,
      orderPaymentStatusInProgress: false,
    }));
    builder.addCase(finalizeOrderAndExit.pending, (state) => ({
      ...state,
      orderFinalizationInProgress: true,
    }));
    builder.addCase(finalizeOrderAndExit.rejected, (state) => ({
      ...state,
      orderFinalizationInProgress: false,
    }));
    builder.addCase(finalizeOrderAndExit.fulfilled, (state) => ({
      ...state,
      orderFinalizationInProgress: false,
    }));
  },
});

const { reducer } = orderPaymentSlice;

export const {
  clearOrderPaymentState,
  clearActivePayment,
  setActivePaymentMethod,
  setActivePaymentStep,
  setActivePaymentTip,
  setActivePaymentCustomerPaysWith,
  setSuccessfulTrigger,
  setAbandonTrigger,
  setCancelTrigger,
  addActivePayment,
  setActivePayment,
  setPaymentParts,
  setSelectedPaymentMethodGroupName,
} = orderPaymentSlice.actions;

export default reducer;
