import { createSlice } from '@reduxjs/toolkit';
import { fetchAllToppings, fetchToppingCategories } from 'stores/Toppings/toppings.thunk-actions';
import { Topping, ToppingCategory } from 'typings/Products';

export interface ToppingsState {
  toppings: Topping[];
  toppingCategories: ToppingCategory[];
}

const initialState: ToppingsState = {
  toppingCategories: [],
  toppings: [],
};

const toppingsSlice = createSlice({
  name: '[TOPPINGS]',
  initialState,
  reducers: {
    resetToppingsState: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(fetchAllToppings.fulfilled, (state, action) => ({
      ...state,
      toppings: action.payload,
    }));
    builder.addCase(fetchToppingCategories.fulfilled, (state, action) => ({
      ...state,
      toppingCategories: action.payload,
    }));
  },
});

const { reducer } = toppingsSlice;
export const { resetToppingsState } = toppingsSlice.actions;

export default reducer;
