/* eslint-disable no-restricted-globals */
import { OrderFilters } from 'containers/AllOrders/AllOrdersTypes';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { OrderColumn, OrderFilter } from 'stores/AllOrders';
import Validators from 'utils/validation/ValidationConstants';

export const MAX_SEARCH_DAYS_RANGE = 7;

export enum HeaderOptions {
  open = 'openPickup',
  paused = 'pausedOrders',
  all = 'allOrders',
}

export enum OrderStatus {
  ToPrepare = 'ToPrepare',
  InPreparation = 'InPreparation',
  Prepared = 'Prepared',
  Completed = 'Completed',
  Canceled = 'Canceled',
  ReadyForDispatch = 'ReadyForDispatch',
  CreatingOrder = 'CreatingOrder',
  AwaitingExternalPayment = 'AwaitingExternalPayment',
  PaymentSettledButNotYetSuppliedToStore = 'PaymentSettledButNotYetSuppliedToStore',
  OutForDelivery = 'OutForDelivery',
  ReceivedButFutureOrder = 'ReceivedButFutureOrder',
  AwaitingExternalValidation = 'AwaitingExternalValidation',
  ExternalValidationFailed = 'ExternalValidationFailed',
  AwaitingExternalCreation = 'AwaitingExternalCreation',
  ExternalCreationFailed = 'ExternalCreationFailed',
  ExternalCreationTimedOut = 'ExternalCreationTimedOut',
  AwaitingFiscalization = 'AwaitingFiscalization',
  ExternalPaymentFailed = 'ExternalPaymentFailed',
}

export const OpenOrderStatuses = [
  OrderStatus.ToPrepare,
  OrderStatus.InPreparation,
  OrderStatus.ReadyForDispatch,
  OrderStatus.OutForDelivery,
];
export const UnpaidOrderStatuses = [OrderStatus.AwaitingExternalPayment];
export const UnpaidOrderStatusesWhenFiscalizationEnabled = [
  OrderStatus.AwaitingExternalPayment,
  OrderStatus.ExternalPaymentFailed,
];
export const UsedOrderStatuses = [...OpenOrderStatuses, OrderStatus.Canceled, OrderStatus.Completed];

export const deliveryFilterOptions: string[] = [
  PickUpTypesValues.takeAway,
  PickUpTypesValues.delivery,
  PickUpTypesValues.pickUp,
];

export enum OrderColumnNames {
  'storeName' = 'storeId',
  'ticketNumber' = 'ticketNumber',
  'name' = 'customerName',
  'address' = 'address',
  'waitTime' = 'waitTime',
  'price' = 'price',
  'status' = 'orderStatus',
  'deliveryType' = 'pickupType',
}

export const NotSortableColumns = [OrderColumnNames.waitTime];

export const AvailableColumns: OrderColumn[] = [
  {
    columnSortNumber: 0,
    name: 'Store Name',
    key: OrderColumnNames.storeName,
    align: 'left',
  },
  {
    columnSortNumber: 1,
    name: 'Ticket Number',
    key: OrderColumnNames.ticketNumber,
    align: 'right',
  },
  {
    columnSortNumber: 2,
    name: 'Customer Name',
    key: OrderColumnNames.name,
    align: 'left',
  },
  {
    columnSortNumber: 3,
    name: 'Delivery Address',
    key: OrderColumnNames.address,
    align: 'left',
  },
  {
    columnSortNumber: 4,
    name: 'Waiting time',
    key: OrderColumnNames.waitTime,
    align: 'right',
  },
  {
    columnSortNumber: 5,
    name: 'Amount',
    key: OrderColumnNames.price,
    align: 'right',
  },
  {
    columnSortNumber: 6,
    name: 'Status',
    key: OrderColumnNames.status,
    align: 'left',
  },
  {
    columnSortNumber: 7,
    name: 'Order type',
    key: OrderColumnNames.deliveryType,
    align: 'left',
  },
];

export const availableFilters: OrderFilter[] = [
  {
    filterSortNumber: 0,
    name: 'storeName',
    type: {
      component: 'select',
      options: [],
    },
  },
  {
    filterSortNumber: 1,
    name: 'channel',
    type: {
      component: 'select',
      options: [
        { key: 1, displayName: 'POS' },
        { key: 2, displayName: 'Self order kiosk' },
        { key: 3, displayName: 'Website' },
      ],
    },
  },
  {
    filterSortNumber: 2,
    name: 'paymentMethod',
    type: {
      component: 'select',
      options: [],
    },
  },
  {
    filterSortNumber: 3,
    name: 'orderType',
    type: {
      component: 'select',
      options: [
        { key: 'Delivery', displayName: 'Delivery' },
        { key: 'Pickup', displayName: 'Pick Up' },
        { key: 'TakeAway', displayName: 'Take away' },
        { key: 'DineIn', displayName: 'Dine In' },
      ],
    },
  },
  {
    filterSortNumber: 4,
    name: 'orderStatus',
    type: {
      component: 'select',
      options: UsedOrderStatuses.map((status) => {
        return { key: status.toString(), displayName: status.toString() };
      }),
    },
  },
  {
    filterSortNumber: 5,
    name: 'ticketNumber',
    type: {
      component: 'number',
      validate: (val) => Validators.IsPositiveInteger.Validator().test(val),
      message: 'Value must be positive number',
    },
  },
];

export const submitAllOrdersForm = () => {
  document.getElementById('orders-filters-form')?.dispatchEvent(new Event('submit', { bubbles: true }));
};

export const defaultOrdersFormValues: OrderFilters = {
  searchGeneric: '',
  dateFrom: new Date(),
  dateTo: new Date(),
  channel: [],
  orderStatus: [],
  orderType: [],
  paymentMethod: [],
  storeName: [],
  ticketNumber: '',
};
