import * as EN from './en';
import * as NL from './nl';
import * as PL from './pl';
import * as ES from './es';

const resources = {
  en: {
    dataSync: EN.dataSync,
    common: EN.common,
    orders: EN.orders,
    intake: EN.intake,
    settings: EN.settings,
    authentication: EN.authentication,
    alerts: EN.alerts,
    roles: EN.roles,
    customerDisplay: EN.customerDisplay,
    errorCodes: EN.errorCodes,
  },
  nl: {
    dataSync: NL.dataSync,
    common: NL.common,
    orders: NL.orders,
    intake: NL.intake,
    settings: NL.settings,
    authentication: NL.authentication,
    alerts: NL.alerts,
    roles: NL.roles,
    customerDisplay: NL.customerDisplay,
    errorCodes: NL.errorCodes,
  },
  pl: {
    dataSync: PL.dataSync,
    common: PL.common,
    orders: PL.orders,
    intake: PL.intake,
    settings: PL.settings,
    authentication: PL.authentication,
    alerts: PL.alerts,
    roles: PL.roles,
    customerDisplay: PL.customerDisplay,
    errorCodes: PL.errorCodes,
  },
  es: {
    dataSync: ES.dataSync,
    common: ES.common,
    orders: ES.orders,
    intake: ES.intake,
    settings: ES.settings,
    authentication: ES.authentication,
    alerts: ES.alerts,
    roles: ES.roles,
    customerDisplay: ES.customerDisplay,
    errorCodes: ES.errorCodes,
  },
};

export default resources;
